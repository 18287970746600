import {Injectable} from '@angular/core';
import {PaginationDto} from "../../common/pagination/pagination.dto";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {HttpClient} from "@angular/common/http";
import {OrderStatusEnum} from "../../common/enums/orderStatus.enum";
import {Observable} from "rxjs";
import {CreateOrderDto} from "./dto/createOrder.dto";
import {OrderTypeEnum} from "../../common/enums/orderType.enum";

@Injectable({
  providedIn: 'root'
})
export class OrdersRestService {

  constructor(private http: HttpClient) {
  }

  getOrders(options: {
    userId?: number,
    symbolId?: any,
    status?: OrderStatusEnum,
    pagination?: PaginationDto,
  }) {
    const query: any = {};

    query['filters'] = {};

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    query['sort'] = 'id:DESC';
    query['populate'] = ['symbol'];

    if (options.userId) {
      query['filters'].user = {
        id: {
          $eq: options.userId,
        },
      };
    }

    if (options.symbolId) {
      query['filters'].symbol = {
        id: {
          $eq: options.symbolId,
        },
      };
    }

    if (options.status) {
      query['filters'].status = {
        $eq: options.status,
      };
    }

    return this.http.get(`/orders?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getOrdersAll(options?: {
    sort?: string,
    status?: OrderStatusEnum[],
    type?: OrderTypeEnum[],
    managers?: number[],
    search?: string,
    searchKey?: string,
    pagination?: PaginationDto,
    createdAt?: Date[],
    closedAt?: Date[],
    symbol?: number
  }): Observable<any> {
    const query: any = {};

    query['filters'] = {};

    if (options?.sort) {
      query.sort = options.sort;
    }

    if (options?.status) {
      query.status = options.status;
    }

    if (options?.type) {
      query.type = options.type;
    }

    if (options?.managers) {
      query.managers = options.managers;
    }

    if (options?.search) {
      query.search = options.search;
    }

    if (options?.searchKey) {
      query.searchKey = options.searchKey;
    }

    if (options?.pagination) {
      query['pagination'] = options.pagination;
    }

    if (options?.createdAt) {
      query.createdAt = options.createdAt;
    }

    if (options?.closedAt) {
      query.closedAt = options.closedAt;
    }

    if (options?.symbol) {
      query.symbol = options.symbol;
    }

    return this.http.get(`/orders/getAll?${qs.stringify(query)}`);
  }

  getOrderById(id: number): Observable<any> {
    const query: any = {};
    query['populate'] = ['symbol'];

    return this.http.get(`/orders/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  updateOrderById(id: number, order: any): Observable<any> {
    return this.http.put(`/orders/${id}`, {data: order});
  }

  orderReCalc(orderId: number): Observable<any> {
    return this.http.post(`/orders/calc-profit`, {orderId});
  }

  updateOrderClosedPrice(orderId: number, closedPrice: any): Observable<any> {
    return this.http.post(`/orders/change-closed-price`, {orderId, closedPrice});
  }

  updateOrderClosedAt(orderId: number, closedAt: any): Observable<any> {
    return this.http.post(`/orders/change-closed-at`, {orderId, closedAt});
  }

  updateOrderUSDCount(orderId: number, usdCount: any): Observable<any> {
    return this.http.post(`/orders/change-usd-count`, {orderId, usdCount})
  }

  createOrderByCount(order: CreateOrderDto): Observable<any> {
    return this.http.post('/orders/createByCount', {data: order});
  }

  createOrderByUsdCount(order: CreateOrderDto): Observable<any> {
    return this.http.post('/orders/createByUsdCount', {data: order});
  }

  closeOrder(orderId: number) {
    return this.http.post('/orders/close', {
      data: {
        id: orderId,
      }
    })
  }

  deleteOrder(orderId: number): Observable<any> {
    return this.http.delete(`/orders/${orderId}`);
  }

  reCalculatedOrder(id: number): Observable<any> {
    return this.http.put(`/custom-user/recalc-orders/${id}`, {});
  }
}
