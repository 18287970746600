export function getOneMapAttributesToObj(obj: any) {
  if (obj['data'] && obj['data']['attributes']) {
    for (const [key, value] of Object.entries(obj.data.attributes)) {
      obj.data[key] = value;
    }
    delete obj.data.attributes;
  }
  return obj;
}

export function getAllMapAttributesToObj(obj: any) {
  if (obj['data'] && obj['data'].length > 0) {
    for (const entry of obj.data) {
      for (const [key, value] of Object.entries(entry.attributes)) {
        entry[key] = value;
      }
      delete entry.attributes;
    }
  }
  return obj;
}
