<form
  nz-form
  #changeInfoForm="ngForm"
  autocomplete="off"
  nzLayout="vertical"
  class="login-form"
  (ngSubmit)="changeInfoForm.valid && transferComment()">

  <div class="row">

    <div class="col-md-6">
      <nz-form-item class="relative">

        <nz-form-label
          nzRequired
          nzFor="userId">
          ID Пользователя
        </nz-form-label>

        <nz-form-control>
          <nz-input-group>
            <input
              #userIdField="ngModel"
              class="form-control"
              name="name"
              type="text"
              nz-input
              [(ngModel)]="userId"
              required
              placeholder="ID Пользователя">
          </nz-input-group>
        </nz-form-control>

        <div
          *ngIf="userIdField.invalid && (userIdField.dirty || userIdField.touched)"
          class="form_error">
          <span *ngIf="userIdField.errors?.['required']">
            Обязательное поле.
          </span>
        </div>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item class="relative">

        <nz-form-label
          nzRequired
          nzFor="leadId">
          ID Лида
        </nz-form-label>

        <nz-form-control>
          <nz-input-group>
            <input
              #leadIdField="ngModel"
              class="form-control"
              name="name"
              type="text"
              nz-input
              [(ngModel)]="leadId"
              required
              placeholder="ID Лида">
          </nz-input-group>
        </nz-form-control>

        <div
          *ngIf="leadIdField.invalid && (leadIdField.dirty || leadIdField.touched)"
          class="form_error">
          <span *ngIf="leadIdField.errors?.['required']">
            Обязательное поле.
          </span>
        </div>

      </nz-form-item>
    </div>

    <div class="col-md-12">
      <button
        nz-button
        nzBlock
        [disabled]="sendingForm"
        class="login-form-button"
        [nzType]="'primary'"
        (click)="changeInfoForm.form.markAllAsTouched()">
        Перенести комментарии
      </button>
    </div>

  </div>

</form>
