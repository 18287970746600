import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {CompanyTypeEnum} from "../../common/enums/company.enum";

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) {}

  getCompany(id: number): Observable<any> {
    const query: any = {};
    query['populate'] = ['databases'];

    return this.http.get(`/companies/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  getCompanies(options : {
    pagination?: PaginationDto,
    search?: string,
    type?: CompanyTypeEnum,
    isActive?: boolean
  }): Observable<any> {
    const query: any = {};
    query['filters'] = {};

    query['sort'] = 'id:DESC';
    query['populate'] = ['databases'];

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    if (options.search) {
      query['filters'].name = {
        $containsi: options.search,
      };
    }

    query['filters'].type = {
      $in: options.type
    }

    query['filters'].isActive = {
      $in: options.isActive
    }

    return this.http.get(`/companies?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  createCompany(name: string, type?: CompanyTypeEnum, text?: string, cap: number | null = null) {
    return this.http.post('/companies', {
      data: {
        name,
        type,
        text,
        cap,
      }
    });
  }

  updateCompanyById(id: number, company: any) {
    return this.http.put(`/companies/${id}`, {data: company});
  }

  deleteCompany(id: number): Observable<any> {
    return this.http.delete(`/companies/${id}`);
  }

  getStats(options: {
    from: Date,
    to: Date,
    companies: number[]
  }): Observable<any> {
    return this.http.get(`/companies/statistics/get?${qs.stringify(options)}`);
  }

  getStatsV2(options: {
    from: Date,
    to: Date,
    company: number,
  }): Observable<any> {
    return this.http.get(`/companies/statistics/get2?${qs.stringify(options)}`)
  }

  getDailyCount(options: {
    from: Date,
    to: Date,
    type: CompanyTypeEnum | null,
  }): Observable<any> {
    return this.http.get(`/companies/statistics/count?${qs.stringify(options)}`);
  }

  getStatsCompaniesWithDatabases(options: {
    from: Date,
    to: Date,
    companies: number[],
  }): Observable<any> {
    return this.http.get(`/companies/statistics/by-companies?${qs.stringify(options)}`)
  }

  getDepositStats(options: {
    from: Date,
    to: Date,
    companies: number[],
  }): Observable<any> {
    return this.http.get(`/transactions/stats-by-companies?${qs.stringify(options)}`)
  }

  getManagersStatistics(options: {
    from: Date,
    to: Date,
    managers: number[],
  }): Observable<any> {
    return this.http.get(`/companies/statistics/managers?${qs.stringify(options)}`)
  }
}
