import { Component, OnInit } from '@angular/core';
import {NzModalRef} from "ng-zorro-antd/modal";
import {MessagesService} from "../../common/services/message-service/messages.service";

@Component({
  selector: 'app-statistics-settings',
  templateUrl: './statistics-settings.component.html',
  styleUrls: ['./statistics-settings.component.scss']
})
export class StatisticsSettingsComponent implements OnInit {
  reduceStatus = true;
  statisticsType: 'database' | 'country' = 'database';

  constructor(
    private messageService: MessagesService,
    private modal: NzModalRef,
  ) { }

  ngOnInit(): void {
    const reduceStatus = localStorage.getItem('reduceStatus');
    this.reduceStatus = !(reduceStatus && reduceStatus === 'false');

    const statisticsType = localStorage.getItem('statisticsType');
    this.statisticsType = statisticsType && statisticsType === 'country' ? 'country' : 'database';
  }

  updateSettings(): void {
    localStorage.setItem('reduceStatus', JSON.stringify(this.reduceStatus));
    localStorage.setItem('statisticsType', this.statisticsType);

    this.messageService.createMessage(
      'success',
      'Настройки успешно изменены'
    );
    this.modal.close(true);
  }
}
