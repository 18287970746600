import {Component} from '@angular/core';
import {MessagesService} from "../../common/services/message-service/messages.service";
import {LeadService} from "../../rest/leads/lead.service";
import {NzModalRef} from "ng-zorro-antd/modal";

@Component({
  selector: 'app-transfer-comment',
  templateUrl: './transfer-comment.component.html',
  styleUrls: ['./transfer-comment.component.scss']
})
export class TransferCommentComponent {
  leadId: number | undefined;
  userId: number | undefined;

  sendingForm = false;

  constructor(
    private leadService: LeadService,
    private modal: NzModalRef,
    private messageService: MessagesService,
  ) {
  }

  transferComment(): void {
    if (this.leadId && this.userId) {
      this.sendingForm = true;

      this.leadService.transferLeadCommentToUser(this.leadId, this.userId).subscribe(
        _ => {
          this.sendingForm = false;

          this.messageService.createMessage(
            'success',
            'Комментарии успешно перенесены с лида в пользователи.'
          );
          this.modal.close();
        }, _ => {
          this.sendingForm = false;
        }
      )
    }
  }
}
