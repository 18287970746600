import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";
import {ProjectType} from "../../common/services/settings/project";
import {ProjectTypeEnum} from "../../common/enums/projectType.enum";

export enum LeadStatusEnum {
  NEW = 'NEW',
  CALL_TO_CLOSE = 'CALL_TO_CLOSE',
  HIGH_POTENTIAL = 'HIGH_POTENTIAL',
  LOW_POTENTIAL = 'LOW_POTENTIAL',
  CUSTOM_0 = 'CUSTOM_0',
  REGISTERED = 'REGISTERED',
  RECOVERY = 'RECOVERY',
  NO_POTENTIAL = 'NO_POTENTIAL',
  IN_PRACTICE = 'IN_PRACTICE',
  ACTIVE = 'ACTIVE',
  MIX = 'MIX',
  DEPOSIT = 'DEPOSIT',
  SPEAKING = 'SPEAKING',
  PART_FTD = 'PART_FTD',
  HOLD = 'HOLD',
  WRONG_INFO = 'WRONG_INFO',
  WRONG_NUMBER = 'WRONG_NUMBER',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  INVALID_LANGUAGE = 'INVALID_LANGUAGE',
  INVALID = 'INVALID',
  UNDER_18 = 'UNDER_18',
  NO_ANSWER = 'NO_ANSWER',
  NO_INTEREST = 'NO_INTEREST',
  DUPLICATE = 'DUPLICATE',
  DUPLICATE_IMPORT = 'DUPLICATE_IMPORT',
  OTHER = 'OTHER',
  NO_ANSWER_1_5 = 'NO_ANSWER_1_5',
  NEWER_ANSWER = 'NEWER_ANSWER',
  CALL_BACK = 'CALL_BACK',

  IN_WORK = 'IN_WORK',
  HANG_UP = 'HANG_UP',
  FAILED_POTENTIAL = 'FAILED_POTENTIAL',
  PRE_DEPOSIT = 'PRE_DEPOSIT',
  NA1 = 'NA1',
  NA2 = 'NA2',
  NA3 = 'NA3',
  DO_NOT_CALL = 'DO_NOT_CALL',
  REASSIGN = 'REASSIGN',
  INITIAL_CALL = 'INITIAL_CALL'
}

export const leadStatusList: NzTableFilterList =
  ProjectType === ProjectTypeEnum.CRM_1 ?
    [
      {
        text: 'New',
        value: LeadStatusEnum.NEW,
      },
      {
        text: 'No Potential',
        value: LeadStatusEnum.NO_POTENTIAL,
      },
      {
        text: 'No Answer',
        value: LeadStatusEnum.NO_ANSWER,
      },
      // {
      //   text: 'No Answer 1-5',
      //   value: LeadStatusEnum.NO_ANSWER_1_5,
      // },
      {
        text: 'Newer Answer',
        value: LeadStatusEnum.NEWER_ANSWER,
      },
      {
        text: 'Invalid',
        value: LeadStatusEnum.INVALID,
      },
      {
        text: 'Part FTD',
        value: LeadStatusEnum.PART_FTD,
      },
      {
        text: 'Duplicate',
        value: LeadStatusEnum.DUPLICATE,
      },
      {
        text: 'Duplicate Import',
        value: LeadStatusEnum.DUPLICATE_IMPORT,
      },
      {
        text: 'Wrong Info',
        value: LeadStatusEnum.WRONG_INFO,
      },
      {
        text: 'Wrong Number',
        value: LeadStatusEnum.WRONG_NUMBER,
      },
      {
        text: 'Invalid Country',
        value: LeadStatusEnum.INVALID_COUNTRY,
      },
      {
        text: 'Invalid Language',
        value: LeadStatusEnum.INVALID_LANGUAGE,
      },
      {
        text: 'Under 18',
        value: LeadStatusEnum.UNDER_18,
      },
      {
        text: 'In Practice',
        value: LeadStatusEnum.IN_PRACTICE,
      },
      {
        text: 'Registered',
        value: LeadStatusEnum.REGISTERED,
      },
      {
        text: 'Deposit',
        value: LeadStatusEnum.DEPOSIT,
      },
      {
        text: 'Low Potential',
        value: LeadStatusEnum.LOW_POTENTIAL,
      },
      // {
      //   text: 'Call to Close',
      //   value: LeadStatusEnum.CALL_TO_CLOSE,
      // },
      // {
      //   text: 'Recovery',
      //   value: LeadStatusEnum.RECOVERY,
      // },
      {
        text: 'Call Back',
        value: LeadStatusEnum.CALL_BACK,
      },
      {
        text: 'Test',
        value: LeadStatusEnum.OTHER,
      },
      {
        text: 'Other',
        value: LeadStatusEnum.HOLD,
      },
      {
        text: 'No interest',
        value: LeadStatusEnum.NO_INTEREST,
      },
      // {
      //   text: 'Active',
      //   value: LeadStatusEnum.ACTIVE,
      // },
      {
        text: 'Speaking',
        value: LeadStatusEnum.SPEAKING,
      },
      {
        text: 'Mix',
        value: LeadStatusEnum.MIX,
      },
      {
        text: 'Передача',
        value: LeadStatusEnum.CUSTOM_0,
      },
    ] :
    ProjectType === ProjectTypeEnum.CRM_2 ?
      [
        {
          text: 'New',
          value: LeadStatusEnum.NEW,
        },
        {
          text: 'No Potential',
          value: LeadStatusEnum.NO_POTENTIAL,
        },
        {
          text: 'High Potential',
          value: LeadStatusEnum.HIGH_POTENTIAL,
        },
        {
          text: 'Low Potential',
          value: LeadStatusEnum.LOW_POTENTIAL,
        },
        {
          text: 'No Answer',
          value: LeadStatusEnum.NO_ANSWER,
        },
        {
          text: 'Newer Answer',
          value: LeadStatusEnum.NEWER_ANSWER,
        },
        // {
        //   text: 'Invalid',
        //   value: LeadStatusEnum.INVALID,
        // },
        // {
        //   text: 'Part FTD',
        //   value: LeadStatusEnum.PART_FTD,
        // },
        {
          text: 'Duplicate',
          value: LeadStatusEnum.DUPLICATE,
        },
        {
          text: 'Duplicate Import',
          value: LeadStatusEnum.DUPLICATE_IMPORT,
        },
        {
          text: 'Wrong Info',
          value: LeadStatusEnum.WRONG_INFO,
        },
        {
          text: 'Wrong Number',
          value: LeadStatusEnum.WRONG_NUMBER,
        },
        {
          text: 'Invalid Country',
          value: LeadStatusEnum.INVALID_COUNTRY,
        },
        {
          text: 'Invalid Language',
          value: LeadStatusEnum.INVALID_LANGUAGE,
        },
        {
          text: 'Under 18',
          value: LeadStatusEnum.UNDER_18,
        },
        // {
        //   text: 'In Practice',
        //   value: LeadStatusEnum.IN_PRACTICE,
        // },
        {
          text: 'Registered',
          value: LeadStatusEnum.REGISTERED,
        },
        {
          text: 'Deposit',
          value: LeadStatusEnum.DEPOSIT,
        },
        {
          text: 'Failed Deposit',
          value: LeadStatusEnum.FAILED_POTENTIAL,
        },
        // {
        //   text: 'Call to Close',
        //   value: LeadStatusEnum.CALL_TO_CLOSE,
        // },
        {
          text: 'Recovery',
          value: LeadStatusEnum.RECOVERY,
        },
        {
          text: 'Call Back',
          value: LeadStatusEnum.CALL_BACK,
        },
        {
          text: 'Test',
          value: LeadStatusEnum.OTHER,
        },
        {
          text: 'Hold',
          value: LeadStatusEnum.HOLD,
        },
        {
          text: 'No interest',
          value: LeadStatusEnum.NO_INTEREST,
        },
        {
          text: 'Active',
          value: LeadStatusEnum.ACTIVE,
        },
        {
          text: 'Speaking',
          value: LeadStatusEnum.SPEAKING,
        },
        {
          text: 'Mix',
          value: LeadStatusEnum.MIX,
        },
        {
          text: 'Initial Call',
          value: LeadStatusEnum.INITIAL_CALL,
        },
      ] :
      ProjectType === ProjectTypeEnum.CRM_3 ?
        [
          {
            text: 'New',
            value: LeadStatusEnum.NEW,
          },
          {
            text: 'No Potential',
            value: LeadStatusEnum.NO_POTENTIAL,
          },
          {
            text: 'No Answer',
            value: LeadStatusEnum.NO_ANSWER,
          },
          {
            text: 'No Answer 1-5',
            value: LeadStatusEnum.NO_ANSWER_1_5,
          },
          {
            text: 'Newer Answer',
            value: LeadStatusEnum.NEWER_ANSWER,
          },
          {
            text: 'Invalid',
            value: LeadStatusEnum.INVALID,
          },
          {
            text: 'Part FTD',
            value: LeadStatusEnum.PART_FTD,
          },
          {
            text: 'Duplicate',
            value: LeadStatusEnum.DUPLICATE,
          },
          {
            text: 'Duplicate Import',
            value: LeadStatusEnum.DUPLICATE_IMPORT,
          },
          {
            text: 'Wrong Info',
            value: LeadStatusEnum.WRONG_INFO,
          },
          {
            text: 'Wrong Number',
            value: LeadStatusEnum.WRONG_NUMBER,
          },
          {
            text: 'Invalid Country',
            value: LeadStatusEnum.INVALID_COUNTRY,
          },
          {
            text: 'Invalid Language',
            value: LeadStatusEnum.INVALID_LANGUAGE,
          },
          {
            text: 'Under 18',
            value: LeadStatusEnum.UNDER_18,
          },
          {
            text: 'In Practice',
            value: LeadStatusEnum.IN_PRACTICE,
          },
          {
            text: 'Registered',
            value: LeadStatusEnum.REGISTERED,
          },
          {
            text: 'Deposit',
            value: LeadStatusEnum.DEPOSIT,
          },
          {
            text: 'Call to Close',
            value: LeadStatusEnum.CALL_TO_CLOSE,
          },
          {
            text: 'Recovery',
            value: LeadStatusEnum.RECOVERY,
          },
          {
            text: 'Call Back',
            value: LeadStatusEnum.CALL_BACK,
          },
          {
            text: 'Other',
            value: LeadStatusEnum.OTHER,
          },
          {
            text: 'Hold',
            value: LeadStatusEnum.HOLD,
          },
          {
            text: 'No interest',
            value: LeadStatusEnum.NO_INTEREST,
          },
          {
            text: 'Active',
            value: LeadStatusEnum.ACTIVE,
          },
          {
            text: 'Speaking',
            value: LeadStatusEnum.SPEAKING,
          },
          {
            text: 'Mix',
            value: LeadStatusEnum.MIX,
          },
        ] :
        [];
