import {Component, Input, OnInit} from '@angular/core';
import {FullUserDto} from "../../../rest/user/dto/fullUser.dto";
import {UserRoleEnum} from "../../../common/enums/userRole.enum";
import {NzModalRef} from "ng-zorro-antd/modal";
import {AuthService} from "../../../common/auth/auth.service";
import {MessagesService} from "../../../common/services/message-service/messages.service";
import {FiltersRestService} from "../../../rest/filters/filters-rest.service";
import {UserRestService} from "../../../rest/user/user-rest.service";
import {FullManagerDto} from "../../../rest/auth/dto/full-manager.dto";
import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";
import {PaginationDto} from "../../../common/pagination/pagination.dto";
import {CompaniesService} from "../../../rest/companies/companies.service";
import {countries} from "../../../common/country/countries";
import {userStatusList} from "../../../pages/users/userStatus.enum";
import {leadStatusList} from "../../../pages/leads/lead-status.enum";
import {ManagersService, User} from "../../../common/services/managers/managers.service";
import {dateRangegWithEnum, formattedDateRanges} from "../../../common/dates/date-ranges";

@Component({
  selector: 'app-create-filter',
  templateUrl: './create-filter.component.html',
  styleUrls: ['./create-filter.component.scss']
})
export class CreateFilterComponent implements OnInit {
  @Input() type: 'LEAD' | 'USER' = 'USER';

  // me
  manager: FullUserDto | undefined;
  role: UserRoleEnum | undefined;

  // managers
  managers: FullManagerDto[] = [];
  managersForFilter: NzTableFilterList = [];
  editedManagerList: User[] = [];

  // companies
  companies: any[] = [];
  companiesForFilter: NzTableFilterList = [];

  data: any = {
    managers: [],
    companies: [],
    status: [],
    name: undefined,
    country: undefined,
    source: undefined,

    createdAtDate: undefined,
    lastCommentDate: undefined,

    hideNotActiveCompanies: true,
  }

  // country
  countryList = countries;

  // helpers
  helpers = {
    loadManagers: false,
    loadCompanies: false,
  }

  // enums
  UserRoleEnum = UserRoleEnum;

  // status
  userStatus = userStatusList;
  leadStatus = leadStatusList;
  statusForFilter: NzTableFilterList | NzTableFilterList = [];

  // ranges
  ranges = dateRangegWithEnum;

  constructor(
    private modal: NzModalRef,
    private authService: AuthService,
    private messageService: MessagesService,
    private userRestService: UserRestService,
    private companiesService: CompaniesService,
    private managersService: ManagersService,
    private filterResetService: FiltersRestService,
  ) {
  }

  ngOnInit(): void {
    this.getMe();

    if (this.type === 'USER') {
      this.statusForFilter = this.userStatus;
    }

    if (this.type === 'LEAD') {
      this.statusForFilter = this.leadStatus;
    }
  }

  getMe(): void {
    this.authService.managerData.subscribe(
      user => {
        if (user) {
          this.manager = new FullUserDto(user);
        }
      }
    );

    this.authService.userRole.subscribe(
      role => {
        if (role) {
          this.role = role;
          this.getManagers();
        }
      }
    )
  }

  // managers
  getManagers(): void {
    if (this.role && (this.role === UserRoleEnum.SUPERMANAGER || this.role === UserRoleEnum.ADMIN)) {
      this.userRestService.getManagers()
        .subscribe(response => {
          this.managers = response.data;

          this.managersForFilter = this.managers.map(manager => {
            return {
              text: manager.fullName,
              value: manager.id,
            }
          });

          this.editedManagerList = this.managersService.mapUsers(this.managers);
          this.helpers.loadManagers = true;

          this.getCompanies();
        });
    } else {
      this.getCompanies();
    }
  }

  // companies
  getCompanies(): void {
    const data = {
      pagination: new PaginationDto({pageSize: 100}),
    }

    this.companiesService.getCompanies(data)
      .subscribe(response => {
        response.data.forEach((company: any, index: number) => {
          this.addCompanyToFilterList(company);
        });

        this.helpers.loadCompanies = true;
      });
  }

  addCompanyToFilterList(company: any) {
    this.companies.push({
      isActive: company.isActive,
      text: company.name,
      value: company.id,
    });

    this.filterCompaniesList();
  }

  filterCompaniesList(): void {
    this.companiesForFilter = this.data.hideNotActiveCompanies ?
      this.companies.filter((company: any) => company.isActive) : this.companies;
  }

  // event
  changeSelectEvent(event: any, type: 'companies' | 'managers' | 'country' | 'status') {
    if (type === 'companies') {
      this.data.companies = event;
    }

    if (type === 'country') {
      this.data.countries = event;
    }

    if (type === 'managers') {
      this.data.managers = event;
    }

    if (type === 'status') {
      this.data.status = event;
    }
  }

  // change companies status
  changeActiveCompaniesList(event: any): void {
    if (typeof event == "boolean") {

      this.data.companies = [];
      this.data.hideNotActiveCompanies = event;

      this.filterCompaniesList();
    }
  }

  // create
  createFilter(): void {
    if (this.data.name && this.manager?.id) {
      const filter = {
        user: this.manager.id,
        data: {
          name: this.data.name,
          type: this.type,
          filters: {
            createdAtDate: this.data.createdAtDate,
            lastCommentDate: this.data.lastCommentDate,
            source: this.data.source,
            status: this.data.status,
            managers: this.data.managers,
            companies: this.data.companies,
            country: this.data.countries,
          }
        }
      };

      this.filterResetService.createFilter(filter).subscribe(
        _ => {
          this.modal.close(true);
        }
      )
    }
  }

  // date changes
  changeCreatedAtDate(createdAtDates: any): void {
    this.data.createdAtDate = createdAtDates;
  }

  changeCommentDate(lastCommentDates: any): void {
    this.data.lastCommentDate = lastCommentDates;
  }
}
