import {NgModule} from '@angular/core';
import {PammRestService} from "./pamm-rest.service";

@NgModule({
  providers: [
    PammRestService,
  ]
})
export class PammRestModule {
}
