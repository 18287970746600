export enum ProjectTypeEnum {
  CRM_1 = 'CRM_1',
  CRM_2 = 'CRM_2',
  CRM_3 = 'CRM_3',
  CRM_4 = 'CRM_4',
  CRM_5 = 'CRM_5',
  CRM_6 = 'CRM_6',
  CRM_7 = 'CRM_7',
  CRM_8 = 'CRM_8',
  CRM_9 = 'CRM_9',
  CRM_10 = 'CRM_10',
}
