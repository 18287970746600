import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from "../auth/auth.service";
import {UserRoleEnum} from "../enums/userRole.enum";

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let roles = route.data.roles as Array<UserRoleEnum>;
    const role = this.authService.getRole();

    if (!role) {
      return false;
    }

    if (!roles.includes(role)) {
      if(role === UserRoleEnum.CRM_MANAGER) {
        this.router.navigateByUrl('/leads');
      } else {
        this.router.navigateByUrl('/users');
      }
      return false;
    }

    return true;
  }

}
