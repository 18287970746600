import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {WithDrawalMethodDto} from "./dto/withDrawalMethod.dto";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
// @ts-ignore
import * as qs from "qs";
import {map} from "rxjs/operators";
import {WithdrawalMethodFullDto} from "./dto/withdrawalMethodFull.dto";
import {StatusEnum} from "../../common/enums/status.enum";
import {PaginationDto} from "../../common/pagination/pagination.dto";

@Injectable()
export class WithdrawalRestService {

  constructor(private http: HttpClient) {
  }

  getWithdrawals(id?: number, pagination?: PaginationDto): Observable<any> {
    const query: any = {};

    query.filters = {};

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (id) {
      query['filters'] = {
        user : {
          id: {
            $eq: id,
          }
        }
      }
    }

    query['sort'] = 'id:DESC';

    return this.http.get(`/withdrawal-methods?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getApprovedWithdrawals(id?: number, pagination?: PaginationDto): Observable<any> {
    const query: any = {};

    query['filters'] = {};

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (id) {
      query['filters'] = {
        user : {
          id: {
            $eq: id,
          }
        },
        status: {
          $eq : 'APPROVED'
        }
      }
    } else {
      query['filters'] = {
        status: {
          $eq : 'APPROVED'
        }
      }
    }

    query['sort'] = 'id:DESC';

    return this.http.get(`/withdrawal-methods?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getWithdrawalsById(id: number, fields ?: string[]): Observable<any> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    return this.http.get(`/withdrawal-methods/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  updateWithdrawalById(id: number, withdraw: WithdrawalMethodFullDto): Observable<any> {
    return this.http.put(`/withdrawal-methods/${id}`, {data: withdraw});
  }

  deleteWithdrawalById(id: number): Observable<any> {
    return this.http.delete(`/withdrawal-methods/${id}`);
  }

  createWithdrawal(withdrawal: WithDrawalMethodDto): Observable<any> {
    return this.http.post('/withdrawal-methods', {data: withdrawal});
  }
}
