import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../common/auth/auth.service";
import {FullUserDto} from "../../rest/user/dto/fullUser.dto";
import {UserRoleEnum} from "../../common/enums/userRole.enum";
import {FiltersRestService} from "../../rest/filters/filters-rest.service";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {FilterDto} from "../../rest/filters/dto/filter.dto";
import {MessagesService} from "../../common/services/message-service/messages.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {CreateFilterComponent} from "./create-filter/create-filter.component";
import {UserRestService} from "../../rest/user/user-rest.service";
import {CompaniesService} from "../../rest/companies/companies.service";
import {ManagersService} from "../../common/services/managers/managers.service";
import {FullManagerDto} from "../../rest/auth/dto/full-manager.dto";
import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";
import {UpdateFilterComponent} from "./update-filter/update-filter.component";
import {LeadStatusEnum, leadStatusList} from "../../pages/leads/lead-status.enum";
import {UserStatusEnum, userStatusList} from "../../pages/users/userStatus.enum";
import {dateRangegWithEnum} from "../../common/dates/date-ranges";

@Component({
  selector: 'app-preset-filters',
  templateUrl: './preset-filters.component.html',
  styleUrls: ['./preset-filters.component.scss']
})
export class PresetFiltersComponent implements OnInit {
  @Input() type: 'LEAD' | 'USER' = 'USER';

  // me
  manager: FullUserDto | undefined;
  role: UserRoleEnum | undefined;

  // filters list
  filtersList: FilterDto[] = [];

  // load list
  helpers = {
    loadList: false,
    initList: false,
  }

  // pagination
  paginationOptions: PaginationDto = new PaginationDto({
    page: 1,
    pageCount: 1,
    pageSize: 50,
    total: 0,
  });

  // managers
  managers: FullManagerDto[] = [];
  managersForFilter: NzTableFilterList = [];

  // companies
  companies: any[] = [];
  companiesForFilter: NzTableFilterList = [];

  // enums
  UserRoleEnum = UserRoleEnum;

  // status
  userStatus = userStatusList;
  leadStatus = leadStatusList;

  // date ranges
  ranges = dateRangegWithEnum;

  constructor(
    private modal: NzModalService,
    private authService: AuthService,
    private messageService: MessagesService,
    private userRestService: UserRestService,
    private companiesService: CompaniesService,
    private managersService: ManagersService,
    private filterResetService: FiltersRestService,
  ) {
  }

  ngOnInit(): void {
    this.getMe();
  }

  // get me
  getMe(): void {
    this.authService.managerData.subscribe(
      user => {
        if (user) {
          this.manager = new FullUserDto(user);
        }
      }
    );

    this.authService.userRole.subscribe(
      role => {
        if (role) {
          this.role = role;
          this.getManagers();
        }
      }
    )
  }

  // get filters
  getFilters(): void {
    if (this.manager?.id) {
      this.helpers.loadList = true;
      this.filterResetService.getFilters({
        pagination: this.paginationOptions,
        user: this.manager.id,
        type: this.type,
      }).subscribe(
        filters => {
          this.filtersList = [];
          this.paginationOptions = filters.meta.pagination;

          if (!this.helpers.initList) this.helpers.initList = true;

          this.filtersList = filters.data.map((filter: FilterDto) => {
            return filter;
          });

          this.helpers.loadList = false;
        }
      )
    }
  }

  // managers
  getManagers(): void {
    if (this.role && (this.role === UserRoleEnum.SUPERMANAGER || this.role === UserRoleEnum.ADMIN)) {
      this.userRestService.getManagers()
        .subscribe(response => {
          this.managers = response.data;

          this.managersForFilter = this.managers.map(manager => {
            return {
              text: manager.fullName,
              value: manager.id,
            }
          });

          this.getCompanies();
        });
    } else {
      this.getCompanies();
    }
  }

  // check beautiful name
  checkManagers(id: string): string | undefined {
    const manager = this.managersForFilter.find(item => item.value === id);
    return manager ? manager.text : undefined;
  }

  checkCompanies(id: string): string | undefined {
    const company = this.companiesForFilter.find(item => item.value == id);
    return company ? company.text : undefined;
  }

  checkDate(date: string): string | undefined {
    const dateObject = this.ranges.find((item: any) => item.enum == date);
    return dateObject ? dateObject.text : '-';
  }

  checkStatus(status: LeadStatusEnum | UserStatusEnum, type: 'LEAD' | 'USER'): string {
    if (this.type === 'USER') {
      const statusObject = this.userStatus.find(item => item.value === status);
      return statusObject ? statusObject.text : '-';
    }

    if (this.type === 'LEAD') {
      const statusObject = this.leadStatus.find(item => item.value === status);
      return statusObject ? statusObject.text : '-';
    }

    return '';
  }

  // companies
  getCompanies(): void {
    const data = {
      pagination: new PaginationDto({pageSize: 100}),
    }

    this.companiesService.getCompanies(data)
      .subscribe(response => {
        this.companies = response.data;

        this.companiesForFilter = this.companies.map(companies => {
          return {
            text: companies.name,
            value: companies.id,
          }
        });

        this.getFilters();
      });
  }

  createFilter(): void {
    const modal = this.modal.create({
      nzTitle: 'Создание фильтра',
      nzContent: CreateFilterComponent,
      nzFooter: null,
      nzComponentParams: {
        manager: this.manager,
        type: this.type,
      }
    });

    modal.afterClose
      .subscribe(res => {
        if (res) {
          this.messageService.createMessage(
            'success',
            'Фильтр успешно создан.'
          );
          this.getFilters();
        }
      });
  }

  editFilter(filter: FilterDto): void {
    const modal = this.modal.create({
      nzTitle: 'Редактирование фильтра',
      nzContent: UpdateFilterComponent,
      nzFooter: null,
      nzComponentParams: {
        manager: this.manager,
        id: filter?.id,
      }
    });

    modal.afterClose
      .subscribe(res => {
        if (res) {
          this.messageService.createMessage(
            'success',
            'Фильтр успешно обновлен.'
          );
          this.getFilters();
        }
      });
  }

  showDeleteConfirm(item: FilterDto): void {
    this.modal.confirm({
      nzTitle: '<i>Подтвердите удаление фильтра.</i>',
      nzOkText: 'Подтвердить',
      nzCancelText: 'Отменить',
      nzOnOk: () => this.deleteFilter(item),
    });
  }

  deleteFilter(item: FilterDto) {
    if (item.user.data.id === this.manager?.id && item?.id) {
      this.filterResetService.deleteFilter(item.id).subscribe(
        _ => {
          this.messageService.createMessage(
            'success',
            'Фильтр успешно удален.'
          );

          this.getFilters();
        }
      )
    }
  }
}
