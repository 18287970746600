import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";
import {ProjectType} from "../../common/services/settings/project";
import {ProjectTypeEnum} from "../../common/enums/projectType.enum";
import {LeadStatusEnum} from "../leads/lead-status.enum";

export enum UserStatusEnum {
  NEW = 'NEW',
  CALL_TO_CLOSE = 'CALL_TO_CLOSE',
  HIGH_POTENTIAL = 'HIGH_POTENTIAL',
  RECOVERY = 'RECOVERY',
  NO_POTENTIAL = 'NO_POTENTIAL',
  IN_PRACTICE = 'IN_PRACTICE',
  ACTIVE = 'ACTIVE',
  MIX = 'MIX',
  SPEAKING = 'SPEAKING',
  PART_FTD = 'PART_FTD',
  LOW_POTENTIAL = 'LOW_POTENTIAL',
  ACTIVE_VIP = 'ACTIVE_VIP',
  WELCOME_CALL = 'WELCOME_CALL',
  TEST = 'TEST',
  NO_ANSWER = 'NO_ANSWER',
  DUPLICATE = 'DUPLICATE',
  OTHER = 'OTHER',
  NO_ANSWER_1_5 = 'NO_ANSWER_1_5',
  NEWER_ANSWER = 'NEWER_ANSWER',
  PEREDACHA_M = 'PEREDACHA_M',
  PEREDACHA_K = 'PEREDACHA_K',
  CUSTOM_1 = 'CUSTOM_1',
  CUSTOM_2 = 'CUSTOM_2',
  CUSTOM_3 = 'CUSTOM_3',
  CUSTOM_4 = 'CUSTOM_4',
  CUSTOM_5 = 'CUSTOM_5',

  WRONG_INFO = 'WRONG_INFO',
  WRONG_NUMBER = 'WRONG_NUMBER',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  INVALID_LANGUAGE = 'INVALID_LANGUAGE',
  DEPOSIT = 'DEPOSIT',
  NO_INTEREST = 'NO_INTEREST',

  DATA = "DATA",
  IN_PROGRESS = "IN_PROGRESS",
  HU = "HU",
  DECLINE = "DECLINE",
  NO_INTEREST2 = "NO_INTEREST2",
  NO_ANSWER2 = "NO_ANSWER2",
  NO_ANSWER3 = "NO_ANSWER3",
  NO_ANSWER4 = "NO_ANSWER4",
  FRAUD = "FRAUD"
}

export const userStatusList: NzTableFilterList =
  ProjectType === ProjectTypeEnum.CRM_3 ?
  [
    {
      text: 'New',
      value: UserStatusEnum.NEW,
    },
    {
      text: 'High Potential',
      value: UserStatusEnum.HIGH_POTENTIAL,
    },
    {
      text: 'No Potential',
      value: UserStatusEnum.NO_POTENTIAL,
    },
    {
      text: 'Data',
      value: LeadStatusEnum.DATA,
    },
    {
      text: 'In Progress',
      value: LeadStatusEnum.IN_PROGRESS,
    },
    {
      text: 'HU on Intro',
      value: LeadStatusEnum.HU,
    },
    {
      text: 'Decline',
      value: LeadStatusEnum.DECLINE,
    },
    {
      text: 'No Answer',
      value: UserStatusEnum.NO_ANSWER,
    },
    {
      text: 'No Answer 2',
      value: LeadStatusEnum.NO_ANSWER2,
    },
    {
      text: 'No Answer 3',
      value: LeadStatusEnum.NO_ANSWER3,
    },
    {
      text: 'No Answer 4',
      value: LeadStatusEnum.NO_ANSWER4,
    },
    {
      text: 'Newer Answer',
      value: UserStatusEnum.NEWER_ANSWER,
    },
    {
      text: 'Сall Back',
      value: UserStatusEnum.PEREDACHA_K,
    },
    {
      text: 'Call to Close',
      value: UserStatusEnum.CALL_TO_CLOSE,
    },
    {
      text: 'Recovery',
      value: UserStatusEnum.RECOVERY,
    },
    {
      text: 'Part FTD',
      value: UserStatusEnum.PART_FTD,
    },
    {
      text: 'Active',
      value: UserStatusEnum.ACTIVE,
    },
    {
      text: 'Active VIP',
      value: UserStatusEnum.ACTIVE_VIP,
    },
    {
      text: 'Welcome call',
      value: UserStatusEnum.WELCOME_CALL,
    },
    {
      text: 'Test',
      value: UserStatusEnum.TEST,
    },
    {
      text: 'Wrong Info',
      value: UserStatusEnum.WRONG_INFO,
    },
    {
      text: 'Wrong Number',
      value: UserStatusEnum.WRONG_NUMBER,
    },
    {
      text: 'Invalid Language',
      value: UserStatusEnum.INVALID_LANGUAGE,
    },
    {
      text: 'Deposit',
      value: UserStatusEnum.DEPOSIT,
    },
    {
      text: 'No interest',
      value: UserStatusEnum.NO_INTEREST,
    },
    {
      text: 'No interest 2',
      value: LeadStatusEnum.NO_INTEREST2,
    },
    {
      text: 'Fraud',
      value: LeadStatusEnum.FRAUD,
    },
    {
      text: 'Duplicate',
      value: UserStatusEnum.DUPLICATE,
    },

    // {
    //   text: 'In Practice',
    //   value: UserStatusEnum.IN_PRACTICE,
    // },
    // {
    //   text: 'Speaking',
    //   value: UserStatusEnum.SPEAKING,
    // },
    // {
    //   text: 'Low Potential',
    //   value: UserStatusEnum.LOW_POTENTIAL,
    // },
    // {
    //   text: 'MIX',
    //   value: UserStatusEnum.MIX,
    // },
    // {
    //   text: 'Invalid Country',
    //   value: UserStatusEnum.INVALID_COUNTRY,
    // },
    // {
    //   text: 'Other',
    //   value: UserStatusEnum.OTHER,
    // },
    // {
    //   text: 'No Answer 1-5',
    //   value: UserStatusEnum.NO_ANSWER_1_5,
    // },
  ] : [
      {
        text: 'New',
        value: UserStatusEnum.NEW,
      },
      {
        text: 'High Potential',
        value: UserStatusEnum.HIGH_POTENTIAL,
      },
      {
        text: 'Duplicate',
        value: UserStatusEnum.DUPLICATE,
      },
      {
        text: 'Low Potential',
        value: UserStatusEnum.LOW_POTENTIAL,
      },
      {
        text: 'No Potential',
        value: UserStatusEnum.NO_POTENTIAL,
      },
      {
        text: 'No Answer',
        value: UserStatusEnum.NO_ANSWER,
      },
      {
        text: 'No Answer 1-5',
        value: UserStatusEnum.NO_ANSWER_1_5,
      },
      {
        text: 'Newer Answer',
        value: UserStatusEnum.NEWER_ANSWER,
      },
      {
        text: 'Сall Back',
        value: UserStatusEnum.PEREDACHA_K,
      },
      {
        text: 'Call to Close',
        value: UserStatusEnum.CALL_TO_CLOSE,
      },
      {
        text: 'Recovery',
        value: UserStatusEnum.RECOVERY,
      },
      {
        text: 'In Practice',
        value: UserStatusEnum.IN_PRACTICE,
      },
      {
        text: 'Speaking',
        value: UserStatusEnum.SPEAKING,
      },
      {
        text: 'Part FTD',
        value: UserStatusEnum.PART_FTD,
      },
      {
        text: 'Active',
        value: UserStatusEnum.ACTIVE,
      },
      {
        text: 'Active VIP',
        value: UserStatusEnum.ACTIVE_VIP,
      },
      {
        text: 'MIX',
        value: UserStatusEnum.MIX,
      },
      {
        text: 'Welcome call',
        value: UserStatusEnum.WELCOME_CALL,
      },
      {
        text: 'Test',
        value: UserStatusEnum.TEST,
      },
      {
        text: 'Other',
        value: UserStatusEnum.OTHER,
      },
      {
        text: 'Передача М',
        value: UserStatusEnum.PEREDACHA_M,
      },
      {
        text: 'Передача К',
        value: UserStatusEnum.CUSTOM_2,
      },
      {
        text: 'Передача',
        value: UserStatusEnum.CUSTOM_5,
      },
      {
        text: 'Wrong Info',
        value: UserStatusEnum.WRONG_INFO,
      },
      {
        text: 'Wrong Number',
        value: UserStatusEnum.WRONG_NUMBER,
      },
      {
        text: 'Invalid Country',
        value: UserStatusEnum.INVALID_COUNTRY,
      },
      {
        text: 'Invalid Language',
        value: UserStatusEnum.INVALID_LANGUAGE,
      },
      {
        text: 'Deposit',
        value: UserStatusEnum.DEPOSIT,
      },
      {
        text: 'No interest',
        value: UserStatusEnum.NO_INTEREST,
      }
    ];
