import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private sound: HTMLAudioElement;

  constructor() {
    this.sound = new Audio('assets/sound/event.mp3');
  }

  playNotificationSound() {
    this.sound.play();
  }
}
