import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from "@angular/router";
import {LayoutComponent} from "./layout.component";
import {AuthDashboardGuard} from "../common/guards/auth-dashboard.guard";
import {RoleGuard} from "../common/guards/role.guard";
import {UserRoleEnum} from "../common/enums/userRole.enum";
import {NotCustomerGuard} from "../common/guards/notcustomer.guard";
import {CompanyTypeEnum} from "../common/enums/company.enum";
import {UserTypeEnum} from "../common/enums/userType.enum";
import {LeadStatusEnum} from "../pages/leads/lead-status.enum";
import {LeadStageEnum} from "../common/enums/leadStage.enum";

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthDashboardGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'users',
      },
      {
        path: 'users',
        loadChildren: () => import('../pages/users/users.module').then(m => m.UsersModule),
        canActivate: [RoleGuard],
        data: {
          type: UserTypeEnum.RET,
          roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERMANAGER, UserRoleEnum.MANAGER, UserRoleEnum.TARGETER]
        }
      },
      {
        path: 'users-autologin',
        loadChildren: () => import('../pages/users/users.module').then(m => m.UsersModule),
        canActivate: [NotCustomerGuard],
        data: {
          type: UserTypeEnum.AUTOLOGIN,
        }
      },
      {
        path: 'potential',
        loadChildren: () => import('../pages/users-potential/users-potential.module').then(m => m.UsersPotentialModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERMANAGER,]}
      },
      {
        path: 'notifications',
        loadChildren: () => import('../pages/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERMANAGER, UserRoleEnum.MANAGER]}
      },
      {
        path: 'history',
        loadChildren: () => import('../pages/history/history.module').then(m => m.HistoryModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'statistics',
        loadChildren: () => import('../pages/statistics/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [RoleGuard],
        data: {
          type: CompanyTypeEnum.HOT,
          roles: [
            UserRoleEnum.ADMIN,
            UserRoleEnum.SUPERMANAGER,
            UserRoleEnum.CRM_MANAGER,
          ]
        }
      },
      {
        path: 'statistics-cold',
        loadChildren: () => import('../pages/statistics/statistics/statistics.module').then(m => m.StatisticsModule),
        canActivate: [RoleGuard],
        data: {
          type: CompanyTypeEnum.COLD,
          roles: [
            UserRoleEnum.ADMIN,
            UserRoleEnum.SUPERMANAGER,
            UserRoleEnum.CRM_MANAGER,
          ]
        }
      },
      {
        path: 'statistics2',
        loadChildren: () => import('../pages/statistics/statisticsv2/statistics.module').then(m => m.StatisticsModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'statistics-deposits',
        loadChildren: () => import('../pages/statistics/deposits/deposits.module').then(m => m.DepositsModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'statistics-managers',
        loadChildren: () => import('../pages/statistics/managers/managers.module').then(m => m.ManagersStatisticsModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN, UserRoleEnum.SUPERMANAGER]}
      },
      {
        path: 'daily-count',
        loadChildren: () => import('../pages/statistics/daily-count/daily-count.module').then(m => m.DailyCountModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'leads',
        loadChildren: () => import('../pages/leads/leads.module').then(m => m.LeadsModule),
        canActivate: [NotCustomerGuard],
        data: {type: CompanyTypeEnum.HOT,}
      },
      {
        path: 'leads-new',
        loadChildren: () => import('../pages/leads/leads.module').then(m => m.LeadsModule),
        canActivate: [NotCustomerGuard],
        data: {
          type: CompanyTypeEnum.HOT,
          status: LeadStatusEnum.NEW,
        }
      },
      {
        path: 'leads-draft',
        loadChildren: () => import('../pages/leads/leads.module').then(m => m.LeadsModule),
        canActivate: [RoleGuard],
        data: {
          leadStage: LeadStageEnum.DRAFT,
          roles: [UserRoleEnum.ADMIN]
        }
      },
      {
        path: 'cold-bases',
        loadChildren: () => import('../pages/leads/leads.module').then(m => m.LeadsModule),
        canActivate: [NotCustomerGuard],
        data: {type: CompanyTypeEnum.COLD,}
      },
      {
        path: 'transactions',
        loadChildren: () => import('../pages/transactions/transactions.module').then(m => m.TransactionsListModule),
        canActivate: [NotCustomerGuard],
      },
      {
        path: 'orders',
        loadChildren: () => import('../pages/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [NotCustomerGuard],
      },
      {
        path: 'events',
        loadChildren: () => import('../pages/calendar/notifications.module').then(m => m.EventsModule),
        canActivate: [NotCustomerGuard],
      },
      {
        path: 'tickets',
        loadChildren: () => import('../pages/tickets/tickets.module').then(m => m.TicketsListModule),
        canActivate: [NotCustomerGuard],
      },
      {
        path: 'companies',
        loadChildren: () => import('../pages/companies/companies.module').then(m => m.CompaniesModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'import-csv',
        loadChildren: () => import('../pages/csv/csv.module').then(m => m.CsvModule),
        canActivate: [RoleGuard],
        data: {roles: [UserRoleEnum.ADMIN]}
      },
      {
        path: 'managers',
        loadChildren: () => import('../pages/managers/managers.module').then(m => m.ManagersModule),
        canActivate: [RoleGuard],
        data: {
          roles: [
            UserRoleEnum.ADMIN,
            UserRoleEnum.SUPERMANAGER,
            UserRoleEnum.CRM_MANAGER,
            UserRoleEnum.SYS_ADMIN,
          ]
        }
      },
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class LayoutRoutesModule {
}
