import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import * as qs from "qs";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {LeadStatusEnum} from "../../pages/leads/lead-status.enum";
import {map} from "rxjs/operators";
import {FullUserDto} from "../user/dto/fullUser.dto";
import {Observable} from "rxjs";
import {CompanyTypeEnum} from "../../common/enums/company.enum";
import {PotentialEnum} from "../../common/enums/potential.enum";

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient) {
  }

  getLeads(options?: {
    pagination?: PaginationDto,
    sort?: string,
    search?: string,
    country?: string,
    searchKey?: string,
    status?: LeadStatusEnum[],
    managers?: number[],
    companies?: number[],
    databases?: number[],
    lastComment?: Date[],
    createdAt?: Date[],
    firstDepositeTime?: Date[],
    isExport?: boolean,
    type?: CompanyTypeEnum,
    potential?: PotentialEnum,
    managers_null?: boolean,
    draft?: boolean,
  }): Observable<any> {
    const query: any = {};

    query['populate'] = ['database'];

    if (options?.pagination) {
      query.pagination = options.pagination;
    }

    if (options?.sort) {
      query.sort = options.sort;
    }

    if (options?.search) {
      query.search = options.search;
    }

    if (options?.searchKey) {
      query.searchKey = options.searchKey;
    }

    if (options?.status) {
      query.status = options.status;
    }

    if (options?.country) {
      query.country = options.country;
    }

    if (options?.managers) {
      query.managers = options.managers;
    }

    if (options?.companies) {
      query.companies = options.companies;
    }

    if (options?.databases) {
      query.database = options.databases;
    }

    if (options?.lastComment) {
      query.lastComment = options.lastComment;
    }

    if (options?.createdAt) {
      query.createdAt = options.createdAt;
    }

    if (options?.firstDepositeTime) {
      query.firstDepositeTime = options.firstDepositeTime;
    }

    if (options?.isExport) {
      query.isExport = options.isExport;
    }

    if (options?.type) {
      query.type = options.type;
    }

    if (options?.potential) {
      query.potential = options.potential;
    }

    if(options?.managers_null) {
      query.managers_null = true;
    }

    if(options?.draft) {
      query.draft = true;
    }

    return this.http.get(`/leads?${qs.stringify(query)}`);
  }

  setManagers(user: number, managers: number[]): Observable<any> {
    return this.http.post('/leads/set-managers', {data: {user, managers}})
  }

  setManagersToMany(
    users: number[],
    managers: number[],
    divideManagers?: boolean): Observable<any> {
    return this.http.post('/leads/set-managers-to-many', {
      data: {
        users, managers, divideManagers
      }
    })
  }

  setCompanyAndDatabase(
    users: number[],
    company?: number | null,
    database?: number | null,
    ): Observable<any> {
    return this.http.post('/leads/massive-change-company-and-database', {
      data: {
        users, company, database
      }
    })
  }

  transferLeadCommentToUser(leadId: number, userId: number): Observable<any> {
    return this.http.post('/leads/transform-lead', {leadId, userId});
  }

  setStatusAndManagersToMany(
    users: number[],
    status: LeadStatusEnum,
    managers: number[],
    divideManagers?: boolean): Observable<any> {
    return this.http.post('/leads/set-status-and-managers-to-many', {
      data: {
        users, status, managers, divideManagers
      }
    })
  }

  massiveStatusChange(status: LeadStatusEnum, users: number[]): Observable<any> {
    return this.http.post('/leads/change-status', {status, users,})
  }

  massiveDeletion(leads: number[], skipHistory?: boolean): Observable<any> {
    return this.http.post('/leads/massive-deletion', {
      data:
        {
          leads: leads,
          skipHistory: skipHistory,
        }
    })
  }

  massiveDraft(leads: number[]): Observable<any> {
    return this.http.post('/leads/massive-draft', {
      leads: leads,
    })
  }

  massivePublish(leads: number[]): Observable<any> {
    return this.http.post('/leads/massive-publish', {
      leads: leads,
    })
  }

  checkInstance(data: any): Observable<any> {
    return this.http.post('/leads/check-existence', data)
  }

  updateById(id: number, editedLead: any): Observable<any> {
    return this.http.put(`/leads/${id}`, {data: editedLead});
  }

  getById(id: number, fields?: string[]): Observable<any> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    query['populate'] = ['registeredUser', 'managers', 'company'];

    return this.http.get<FullUserDto>(`/leads/${id}?${qs.stringify(query)}`);
  }

  deleteLead(id: number): Observable<any> {
    return this.http.delete(`/leads/${id}`);
  }

  createLead(lead: any): Observable<any> {
    return this.http.post('/leads', {data: lead});
  }

  getManagers(): Observable<any> {
    const query: any = {};

    query['populate'] = ['role'];

    return this.http.get<{ data: FullUserDto[] }>(`/custom-user/managers?${qs.stringify(query)}`).pipe(
      map(response => {
        response.data = response.data.map(manager => new FullUserDto(manager));
        return response;
      })
    )
  }

  createFromCSV(leads: any[], options: {
    companyId?: number | null,
    status?: LeadStatusEnum | null,
    database?: number | null,
    managersIds?: number[],
    isCSVchecking?: boolean,
    leadDistribution?: boolean,
    ignoreDuplicates?: boolean,
    type?: CompanyTypeEnum | null,
  }): Observable<any> {
    const managersLength = options.managersIds?.length ? options.managersIds?.length : 0;
    let counter = 0;

    return this.http.post('/leads/createFromCSV', {
      isCSVchecking: options.isCSVchecking ? options.isCSVchecking : false,
      ignoreDuplicates: options.ignoreDuplicates ? options.ignoreDuplicates : false,
      leads: leads.map(lead => {
        let managerId = null;

        if (options.managersIds && options.leadDistribution) {
          if (counter < managersLength) {
            managerId = options.managersIds[counter];
          } else {
            managerId = options.managersIds[0];
            counter = 0;
          }
          counter++;
        }

        lead.company = options.companyId;
        lead.status = options.status ? options.status : LeadStatusEnum.NEW;
        lead.database = options.database;

        if (options.leadDistribution) {
          lead.managers = managerId ? [managerId] : [];
        } else {
          lead.managers = options.managersIds ? options.managersIds : [];
        }

        if (options.type) {
          lead.type = options.type;
        }

        return lead;
      }),
    })
  }
}
