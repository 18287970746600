import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {DepositUpdateDto} from "./dto/depositUpdate.dto";
import {DepositCreateDto} from "./dto/depositCreate.dto";

@Injectable({
  providedIn: 'root'
})
export class DepositRestService {

  constructor(private http: HttpClient) {
  }

  getDeposits(id?: number, pagination?: PaginationDto, visible?: boolean): Observable<any> {
    const query: any = {};

    query['sort'] = 'id:DESC';
    query['populate'] = ['files']
    query['filters'] = {};

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (visible) {
      query['filters'].isShown = {
        $eq: visible,
      };
    }

    if(id) {
      query['filters'].user = {
        id: {
          $eq: id,
        },
      };
    }

    query['populate'] = ['receipt']

    return this.http.get(`/deposits?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getDepositById(id: number, fields ?: string[]): Observable<any> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    query['populate'] = ['receipt'];

    return this.http.get(`/deposits/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  createDeposit (deposit: DepositCreateDto): Observable<any> {
    return this.http.post('/deposits', {data: deposit});
  }

  updateDepositById(id: number, deposit: DepositUpdateDto): Observable<any> {
    return this.http.put(`/deposits/${id}`, {data: deposit});
  }

  deleteDepositById(id: number): Observable<any> {
    return this.http.delete(`/deposits/${id}`);
  }

  public uploadFile<T>(files: File[]): Observable<any> {
    let formData = new FormData();

    for (let file of files) {
      formData.append('files', file, file.name);
    }

    return this.http
      .post<T>(
        "/upload",
        formData
      );
  }
}
