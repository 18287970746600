import {UserStatusEnum} from "../../../pages/users/userStatus.enum";
import {PlatformEnum} from "../../../common/enums/platform.enum";
import {DatabaseFullDto} from "../../databases/dto/DatabaseFull.dto";
import {PotentialEnum} from "../../../common/enums/potential.enum";
import {UserTypeEnum} from "../../../common/enums/userType.enum";

export class FullUserDto {
  id: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  balance: number;
  total: number;
  phone?: string;
  additionalPhone?: string;
  additionalEmail?: string;
  city?: string;
  fullAddress?: string;
  country?: string;
  fullName: string;
  managers: FullUserDto[] = [];
  managersIds: number[] = [];
  status: UserStatusEnum;
  fromLead: any;
  isManualDepositActive: boolean;
  notes?: string;
  age?: string;

  createdAt?: string;

  blocked: boolean;
  isBlocked: boolean | null;
  blockingReason?: string;

  lastCommentDate: Date | null;
  lastDepositDate?: string;
  firstDepositDate: Date | null;
  firstDepositeTime?: Date;

  wasOnline: Date | null;
  depositsSum: number;
  bonusesSum: number;

  platform?: PlatformEnum;

  lastComment?: string;

  depositSum?: string;

  firstNameBRI?: string;
  lastNameBRI?: string;

  subdivision?: string;

  company: any;
  database?: DatabaseFullDto;

  settings?: any;
  potential?: PotentialEnum;

  type?: UserTypeEnum;

  constructor(data?: FullUserDto) {
    this.id = data?.id || -1;
    this.username = data?.username;
    this.firstName = data?.firstName;
    this.lastName = data?.lastName;

    this.email = data?.email;
    this.phone = data?.phone;

    this.additionalPhone = data?.additionalPhone;
    this.additionalEmail = data?.additionalEmail;

    this.balance = data?.balance || 0;
    this.total = data?.total || 0;
    this.city = data?.city;
    this.fullAddress = data?.fullAddress;
    this.country = data?.country;
    this.fromLead = data?.fromLead;
    this.fullName = data?.firstName && data?.lastName ? data?.firstName + ' ' + data?.lastName :
      data?.firstName && !data?.lastName ? data?.firstName :
        !data?.firstName && data?.lastName ? data?.lastName :
          '—';
    this.createdAt = data?.createdAt;
    this.managers = data?.managers ? data?.managers.map(manager => new FullUserDto(manager)) : [];
    this.managersIds = data?.managers ? data?.managers.map(manager => manager.id) : [];

    this.isManualDepositActive = data?.isManualDepositActive || false;

    this.status = data?.status || UserStatusEnum.NEW;

    this.blocked = data?.blocked || false;
    this.isBlocked = data?.isBlocked || false;
    this.blockingReason = data?.blockingReason;

    this.notes = data?.notes;

    this.lastCommentDate = data?.lastCommentDate || null;
    this.lastDepositDate = data?.lastDepositDate;
    this.firstDepositDate = data?.firstDepositDate || null;
    this.firstDepositeTime = data?.firstDepositeTime;

    this.wasOnline = data?.wasOnline || null;
    this.depositsSum = data?.depositsSum || 0;
    this.bonusesSum = data?.bonusesSum || 0;

    this.lastComment = data?.lastComment;

    this.depositSum = data?.depositSum;

    this.platform = data?.platform;

    this.firstNameBRI = data?.firstNameBRI;
    this.lastNameBRI = data?.lastNameBRI;

    this.subdivision = data?.subdivision;

    this.company = data?.company;
    this.database = data?.database;

    this.settings = data?.settings;

    this.potential = data?.potential;
    this.age = data?.age;

    this.type = data?.type ? data?.type : UserTypeEnum.RET;
  }
}
