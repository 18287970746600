import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";
import {ProjectType} from "../../common/services/settings/project";
import {ProjectTypeEnum} from "../../common/enums/projectType.enum";

export enum UserStatusEnum {
  NEW = 'NEW',
  CALL_TO_CLOSE = 'CALL_TO_CLOSE',
  HIGH_POTENTIAL = 'HIGH_POTENTIAL',
  RECOVERY = 'RECOVERY',
  NO_POTENTIAL = 'NO_POTENTIAL',
  IN_PRACTICE = 'IN_PRACTICE',
  ACTIVE = 'ACTIVE',
  MIX = 'MIX',
  SPEAKING = 'SPEAKING',
  PART_FTD = 'PART_FTD',
  LOW_POTENTIAL = 'LOW_POTENTIAL',
  ACTIVE_VIP = 'ACTIVE_VIP',
  WELCOME_CALL = 'WELCOME_CALL',
  TEST = 'TEST',
  NO_ANSWER = 'NO_ANSWER',
  DUPLICATE = 'DUPLICATE',
  OTHER = 'OTHER',
  NO_ANSWER_1_5 = 'NO_ANSWER_1_5',
  NEWER_ANSWER = 'NEWER_ANSWER',
  PEREDACHA_M = 'PEREDACHA_M',
  PEREDACHA_K = 'PEREDACHA_K',
  CUSTOM_1 = 'CUSTOM_1',
  CUSTOM_2 = 'CUSTOM_2',
  CUSTOM_3 = 'CUSTOM_3',
  CUSTOM_4 = 'CUSTOM_4',
  CUSTOM_5 = 'CUSTOM_5',

  WRONG_INFO = 'WRONG_INFO',
  WRONG_NUMBER = 'WRONG_NUMBER',
  INVALID_COUNTRY = 'INVALID_COUNTRY',
  INVALID_LANGUAGE = 'INVALID_LANGUAGE',
  DEPOSIT = 'DEPOSIT',
  NO_INTEREST = 'NO_INTEREST',
}

export const userStatusList: NzTableFilterList = ProjectType === ProjectTypeEnum.CRM_3 ?
  [
    {
      text: 'New',
      value: UserStatusEnum.NEW,
    },
    {
      text: 'High Potential',
      value: UserStatusEnum.HIGH_POTENTIAL,
    },
    {
      text: 'Duplicate',
      value: UserStatusEnum.DUPLICATE,
    },
    {
      text: 'Low Potential',
      value: UserStatusEnum.LOW_POTENTIAL,
    },
    {
      text: 'No Potential',
      value: UserStatusEnum.NO_POTENTIAL,
    },
    {
      text: 'No Answer',
      value: UserStatusEnum.NO_ANSWER,
    },
    {
      text: 'No Answer 1-5',
      value: UserStatusEnum.NO_ANSWER_1_5,
    },
    {
      text: 'Newer Answer',
      value: UserStatusEnum.NEWER_ANSWER,
    },
    {
      text: 'Сall Back',
      value: UserStatusEnum.PEREDACHA_K,
    },
    {
      text: 'Call to Close',
      value: UserStatusEnum.CALL_TO_CLOSE,
    },
    {
      text: 'Recovery',
      value: UserStatusEnum.RECOVERY,
    },
    {
      text: 'In Practice',
      value: UserStatusEnum.IN_PRACTICE,
    },
    {
      text: 'Speaking',
      value: UserStatusEnum.SPEAKING,
    },
    {
      text: 'Part FTD',
      value: UserStatusEnum.PART_FTD,
    },
    {
      text: 'Active',
      value: UserStatusEnum.ACTIVE,
    },
    {
      text: 'Active VIP',
      value: UserStatusEnum.ACTIVE_VIP,
    },
    {
      text: 'MIX',
      value: UserStatusEnum.MIX,
    },
    {
      text: 'Welcome call',
      value: UserStatusEnum.WELCOME_CALL,
    },
    {
      text: 'Test',
      value: UserStatusEnum.TEST,
    },
    {
      text: 'Other',
      value: UserStatusEnum.OTHER,
    },
    {
      text: 'Wrong Info',
      value: UserStatusEnum.WRONG_INFO,
    },
    {
      text: 'Wrong Number',
      value: UserStatusEnum.WRONG_NUMBER,
    },
    {
      text: 'Invalid Country',
      value: UserStatusEnum.INVALID_COUNTRY,
    },
    {
      text: 'Invalid Language',
      value: UserStatusEnum.INVALID_LANGUAGE,
    },
    {
      text: 'Deposit',
      value: UserStatusEnum.DEPOSIT,
    },
    {
      text: 'No interest',
      value: UserStatusEnum.NO_INTEREST,
    }
  ] :
  [
    {
      text: 'New',
      value: UserStatusEnum.NEW,
    },
    {
      text: 'High Potential',
      value: UserStatusEnum.HIGH_POTENTIAL,
    },
    {
      text: 'Duplicate',
      value: UserStatusEnum.DUPLICATE,
    },
    {
      text: 'Low Potential',
      value: UserStatusEnum.LOW_POTENTIAL,
    },
    {
      text: 'No Potential',
      value: UserStatusEnum.NO_POTENTIAL,
    },
    {
      text: 'No Answer',
      value: UserStatusEnum.NO_ANSWER,
    },
    {
      text: 'No Answer 1-5',
      value: UserStatusEnum.NO_ANSWER_1_5,
    },
    {
      text: 'Newer Answer',
      value: UserStatusEnum.NEWER_ANSWER,
    },
    {
      text: 'Сall Back',
      value: UserStatusEnum.PEREDACHA_K,
    },
    {
      text: 'Call to Close',
      value: UserStatusEnum.CALL_TO_CLOSE,
    },
    {
      text: 'Recovery',
      value: UserStatusEnum.RECOVERY,
    },
    {
      text: 'In Practice',
      value: UserStatusEnum.IN_PRACTICE,
    },
    {
      text: 'Speaking',
      value: UserStatusEnum.SPEAKING,
    },
    {
      text: 'Part FTD',
      value: UserStatusEnum.PART_FTD,
    },
    {
      text: 'Active',
      value: UserStatusEnum.ACTIVE,
    },
    {
      text: 'Active VIP',
      value: UserStatusEnum.ACTIVE_VIP,
    },
    {
      text: 'MIX',
      value: UserStatusEnum.MIX,
    },
    {
      text: 'Welcome call',
      value: UserStatusEnum.WELCOME_CALL,
    },
    {
      text: 'Test',
      value: UserStatusEnum.TEST,
    },
    {
      text: 'Other',
      value: UserStatusEnum.OTHER,
    },
    {
      text: 'Передача М',
      value: UserStatusEnum.PEREDACHA_M,
    },
    {
      text: 'Передача К',
      value: UserStatusEnum.CUSTOM_2,
    },
    {
      text: 'Передача',
      value: UserStatusEnum.CUSTOM_5,
    },
    {
      text: 'Wrong Info',
      value: UserStatusEnum.WRONG_INFO,
    },
    {
      text: 'Wrong Number',
      value: UserStatusEnum.WRONG_NUMBER,
    },
    {
      text: 'Invalid Country',
      value: UserStatusEnum.INVALID_COUNTRY,
    },
    {
      text: 'Invalid Language',
      value: UserStatusEnum.INVALID_LANGUAGE,
    },
    {
      text: 'Deposit',
      value: UserStatusEnum.DEPOSIT,
    },
    {
      text: 'No interest',
      value: UserStatusEnum.NO_INTEREST,
    }
  ];
