import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TransferCommentComponent} from './transfer-comment/transfer-comment.component';
import {NgZorroModule} from "../common/services/ng-zorro/ng-zorro.module";
import {FormsModule} from "@angular/forms";
import { PresetFiltersComponent } from './preset-filters/preset-filters.component';
import { UpdateFilterComponent } from './preset-filters/update-filter/update-filter.component';
import { CreateFilterComponent } from './preset-filters/create-filter/create-filter.component';
import { SelectFilterComponent } from './preset-filters/select-filter/select-filter.component';
import { StatisticsSettingsComponent } from './statistics-settings/statistics-settings.component';

@NgModule({
  declarations: [
    TransferCommentComponent,
    PresetFiltersComponent,
    UpdateFilterComponent,
    CreateFilterComponent,
    SelectFilterComponent,
    StatisticsSettingsComponent
  ],
  imports: [
    CommonModule,
    NgZorroModule,
    FormsModule,
  ],
  exports: [
    TransferCommentComponent,
    PresetFiltersComponent,
  ]
})
export class ComponentModule {
}
