<ul
  *ngIf="helpers.initList"
  nz-list
  [nzDataSource]="filtersList"
  [nzLoading]="helpers.loadList">

  <li nz-list-item *ngFor="let filter of filtersList" nzNoFlex>

    <ul nz-list-item-actions>
      <nz-list-item-action>
        <button
          nz-button
          nzType="primary"
          nzSize="small"
          nzShape="round"
          (click)="selectFilter(filter)">
          Применить
        </button>

      </nz-list-item-action>
    </ul>

    <div class="d-flex align-items-center text-wrap">
      <span class="font-weight-semibold m-r-5">Название:</span>
      {{ filter.data.name }}
    </div>

  </li>
</ul>

<nz-skeleton *ngIf="!helpers.initList"></nz-skeleton>
