export enum EventTypeEnum {
  PHONE = 'PHONE',
  MESSAGE = 'MESSAGE',
  OTHER = 'OTHER',
}

export enum EventDataEnum {
  LEAD = 'LEAD',
  USER = 'USER',
}
