import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {PaginationDto} from "../../common/pagination/pagination.dto";

@Injectable({
  providedIn: 'root'
})
export class PammRestService {
  constructor(private http: HttpClient) {
  }

  getPamms(pagination?: PaginationDto, fields ?: string[]): Observable<any> {
    const query: any = {};

    query['sort'] = 'rating:ASC';

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (fields) {
      query['fields'] = fields;
    }

    return this.http.get(`/pumps?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getPammById(id: number): Observable<any> {
    return this.http.get(`/pumps/${id}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }
}
