<form
  nz-form
  #tableSettingsForm="ngForm"
  autocomplete="off"
  nzLayout="vertical"
  class="login-form"
  (ngSubmit)="tableSettingsForm.valid && updateSettings()">

  <div class="row">

    <div class="col-md-6">
      <nz-form-item class="relative">

        <nz-form-label
          nzFor="hideFields">
          Активировать сбивку статусов
        </nz-form-label>

        <nz-form-control>

          <nz-select
            name="status"
            [(ngModel)]="reduceStatus"
            (ngModelChange)="reduceStatus = $event;">

            <nz-option
              [nzValue]="true"
              [nzLabel]="'Активировать'">
            </nz-option>

            <nz-option
              [nzValue]="false"
              [nzLabel]="'Деактивировать'">
            </nz-option>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item class="relative">

        <nz-form-label
          nzFor="type">
          Тип статистики
        </nz-form-label>

        <nz-form-control>

          <nz-select
            name="type"
            [(ngModel)]="statisticsType"
            (ngModelChange)="statisticsType = $event;">

            <nz-option
              [nzValue]="'database'"
              [nzLabel]="'По базам'">
            </nz-option>

            <nz-option
              [nzValue]="'country'"
              [nzLabel]="'По странам'">
            </nz-option>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-12">
      <button
        nz-button
        nzBlock
        nzType="primary"
        (click)="tableSettingsForm.form.markAllAsTouched()">
        Сохранить настройки
      </button>
    </div>

  </div>

</form>
