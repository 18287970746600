import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {CreateWithdrawalDto} from "./dto/createWithdrawal.dto";
import {StatusEnum} from "../../common/enums/status.enum";
import {UpdateTransactionDto} from "./dto/updateTransaction.dto";
import {CreateTransactionDto} from "./dto/createTransaction.dto";
import {UserStatusEnum} from "../../pages/users/userStatus.enum";
import {TransactionTypeEnum} from "../../common/enums/transactionType.enum";

@Injectable({
  providedIn: 'root'
})
export class TransactionsRestService {
  UserStatusEnum = UserStatusEnum;
  TransactionTypeEnum = TransactionTypeEnum;

  constructor(private http: HttpClient) {
  }

  getTransactions(id?: number, pagination?: PaginationDto) {
    const query: any = {};

    if (pagination) {
      query['pagination'] = pagination;
    }

    query['populate'] = ['withdrawalMethod'];
    query['sort'] = 'id:DESC';

    if (id) {
      query['filters'] = {
        user: {
          id: {
            $eq: id,
          },
        },
        hidden: {
          $eq: false,
        }
      };
    } else {
      query['filters'] = {
        hidden: {
          $eq: false,
        }
      };
    }

    return this.http.get(`/transactions?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getAllTransactions(options: {
    pagination: PaginationDto,
    sort?: string,
    createdAt: Date[] | undefined,
    status: StatusEnum[],
    userStatus: UserStatusEnum[],
    type: TransactionTypeEnum[],
    managers: number[],
    search: string | undefined,
    searchKey: string | undefined,
    sum: {
      value: number,
      operation: '>' | '<' | '<=' | '>=' | '=' | undefined,
    } | undefined,
  }): Observable<any> {
    return this.http.get(`/transactions/getAll?${qs.stringify(options)}`);
  }

  getTransactionsForAdmin(options: {
    userId?: number,
    pagination?: PaginationDto,
    isDeposit?: boolean,
    status?: StatusEnum,
  }) {
    const query: any = {};

    query['sort'] = 'id:DESC';

    if (!options.isDeposit) {
      query['populate'] = ['withdrawalMethod'];
    }

    if (options.pagination) {
      query['pagination'] = options.pagination;
    }

    if (options.userId) {
      query['filters'] = {
        user: {
          id: {
            $eq: options.userId,
          },
        },
      };
    }

    if (options.isDeposit) {
      query['filters'].isDeposit = {
        $eq: options.isDeposit,
      };
    }

    if (options.status) {
      query['filters'].status = {
        $eq: options.status,
      };
    }

    return this.http.get(`/transactions?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getTransactionById(id: number, fields ?: string[]): Observable<any> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    query['populate'] = ['withdrawalMethod']

    return this.http.get(`/transactions/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  createRubDeposit(transaction: {
    inputSum: number,
    user: number | null,
  }): Observable<any> {
    return this.http.post('/transactions/create-rub-deposit', {data: transaction});
  }

  createTransaction(transaction: CreateTransactionDto): Observable<any> {
    return this.http.post('/transactions', {data: transaction});
  }

  updateTransactionById(id: number, transaction: UpdateTransactionDto): Observable<any> {
    return this.http.put(`/transactions/${id}`, {data: transaction});
  }

  deleteTransactionById(id: number): Observable<any> {
    return this.http.delete(`/transactions/${id}`);
  }
}
