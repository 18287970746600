import {addDays, addMonths, addYears, endOfMonth, startOfMonth, startOfWeek, startOfYear} from "date-fns";
import {NzTableFilterList} from "ng-zorro-antd/table/src/table.types";

export const dateRanges = {
  'Сегодня': [new Date(), new Date()],
  'Текущая неделя': [new Date(addDays(startOfWeek(new Date()), 1)), new Date()],
  'Неделя': [addDays(new Date(), -6), new Date()],
  'Текущий месяц': [startOfMonth(new Date()), endOfMonth(new Date())],
  'Месяц': [addMonths(new Date(), -1), new Date()],
  '3 месяца': [addMonths(new Date(), -3), new Date()],
  '6 месяцев': [addMonths(new Date(), -6), new Date()],
  'Год': [addYears(new Date(), -1), new Date()],
  'Текущий год': [startOfYear(new Date()), new Date()],
  'Все время': [startOfYear(new Date().setFullYear(2020)), new Date()],
};

export const formattedDateRanges: NzTableFilterList = [
  {
    text: 'Сегодня',
    value: [new Date(), new Date()],
  },
  {
    text: 'Текущая неделя',
    value: [new Date(addDays(startOfWeek(new Date()), 1)), new Date()],
  },
  {
    text: 'Неделя',
    value: [addDays(new Date(), -6), new Date()],
  },
  {
    text: 'Текущий месяц',
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    text: 'Месяц',
    value: [addMonths(new Date(), -1), new Date()],
  },
  {
    text: '3 месяца',
    value: [addMonths(new Date(), -3), new Date()],
  },
  {
    text: '6 месяцев',
    value: [addMonths(new Date(), -6), new Date()],
  },
  {
    text: 'Год',
    value: [addYears(new Date(), -1), new Date()],
  },
  {
    text: 'Текущий год',
    value: [startOfYear(new Date()), new Date()],
  },
  {
    text: 'Все время',
    value: [new Date('01-01-2020'), new Date()],
  }
];

export const dateRangegWithEnum: any = [
  {
    text: 'Сегодня',
    enum: 'TODAY',
    value: [new Date(), new Date()],
  },
  {
    text: 'Текущая неделя',
    enum: 'CURRENT_WEEK',
    value: [new Date(addDays(startOfWeek(new Date()), 1)), new Date()],
  },
  {
    text: 'Неделя',
    enum: 'WEEK',
    value: [addDays(new Date(), -6), new Date()],
  },
  {
    text: 'Текущий месяц',
    enum: 'CURRENT_MONTH',
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
  },
  {
    text: 'Месяц',
    enum: 'MONTH',
    value: [addMonths(new Date(), -1), new Date()],
  },
  {
    text: '3 месяца',
    enum: 'THREE_MONTH',
    value: [addMonths(new Date(), -3), new Date()],
  },
  {
    text: '6 месяцев',
    enum: 'SIX_MONTH',
    value: [addMonths(new Date(), -6), new Date()],
  },
  {
    text: 'Год',
    enum: 'YEAR',
    value: [addYears(new Date(), -1), new Date()],
  },
  {
    text: 'Текущий год',
    enum: 'CURRENT_YEAR',
    value: [startOfYear(new Date()), new Date()],
  },
  {
    text: 'Все время',
    enum: 'ALL_TIME',
    value: [new Date('01-01-2020'), new Date()],
  }
];
