import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {Observable} from "rxjs";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {TicketCreateDto} from "./dto/ticketCreate.dto";
import {TicketUpdateDto} from "./dto/ticketUpdate.dto";
import {TicketStatusEnum} from "../../common/enums/ticket.status.enum";

@Injectable({
  providedIn: 'root'
})
export class TicketsRestService {
  TicketStatusEnum = TicketStatusEnum;

  constructor(private http: HttpClient) {
  }

  getTickets(options: {
    createdAt: Date[] | undefined;
    pagination: PaginationDto;
    id: string | null;
    status: any[]
  }): Observable<any> {
    const query: any = {};

    query['sort'] = 'id:DESC';

    if (options.id) {
      query.filters = {
        user: {
          id: {
            $eq: options.id,
          },
        },
      };
    }

    if (options.pagination) {
      query.pagination = options.pagination;
    }

    if(options.createdAt) {
      query.createdAt = options.createdAt;
    }

    if(options.status) {
      query.status = options.status;
    }

    return this.http.get(`/tickets?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getAllTickets(options: {
    pagination: PaginationDto,
    createdAt: Date[] | undefined,
    status: TicketStatusEnum[] | undefined,
    search: string | undefined,
    searchKey: 'id' | 'name' | 'phone' | 'email' | undefined,
  }): Observable<any> {
    return this.http.get(`/tickets/getAll?${qs.stringify(options)}`);
  }

  getTicketById(id: number, fields ?: string[]): Observable<any> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    query['populate'] = ['files']

    return this.http.get(`/tickets/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  createTicket(ticket: TicketCreateDto): Observable<any> {
    return this.http.post('/tickets', {data: ticket});
  }

  updateTicketById(id: number, ticket: TicketUpdateDto): Observable<any> {
    return this.http.put(`/tickets/${id}`, {data: ticket});
  }

  deleteTicketById(id: number): Observable<any> {
    return this.http.delete(`/tickets/${id}`);
  }
}
