import {NgModule} from '@angular/core';
import {WithdrawalRestService} from "./withdrawal-rest.service";

@NgModule({
  providers: [
    WithdrawalRestService,
  ]
})
export class WithdrawalRestModule {
}
