import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {FullUserDto} from "./dto/fullUser.dto";
// @ts-ignore
import * as qs from "qs";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import {UserStatusEnum} from "../../pages/users/userStatus.enum";
import {map} from "rxjs/operators";
import {ChangeEmailDto} from "./dto/changeEmail.dto";
import {UpdatePasswordByAdminDto} from "./dto/updatePasswordByAdmin.dto";
import {getAllMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {CreateManagerDto} from "../auth/dto/create-manager.dto";
import {FullManagerDto} from "../auth/dto/full-manager.dto";
import {PotentialEnum} from "../../common/enums/potential.enum";
import {UserTypeEnum} from "../../common/enums/userType.enum";
import {b} from "@fullcalendar/core/internal-common";

@Injectable()
export class UserRestService {

  constructor(private http: HttpClient) {
  }

  getUsers(data?: {
    pagination?: PaginationDto,
    sort?: string,
    search?: string,
    searchKey?: string,
    status?: UserStatusEnum[],
    managers?: number[],
    companies?: number[]
    platform?: number,
    lastComment?: Date[],
    createdAt?: Date[],
    lastDepositDate?: Date[],
    firstDepositDate?: Date[],
    wasOnline?: Date[],
    country?: string,
    type?: UserTypeEnum,
    depositsSum?: {
      value: number,
      operation: '>' | '<' | '<=' | '>=' | '=' | undefined,
    } | undefined,
    potential?: PotentialEnum,
    managers_null?: boolean,
    commentedManagersIds?: number[],
    commentedManagers?: boolean,
  }): Observable<any> {
    const query: any = {};

    query['populate'] = ['company', 'database'];

    if (data?.pagination) {
      query.pagination = data.pagination;
    }
    if (data?.sort) {
      query.sort = data.sort;
    }
    if (data?.search) {
      query.search = data.search;
    }
    if (data?.searchKey) {
      query.searchKey = data.searchKey;
    }
    if (data?.status) {
      query.status = data.status;
    }
    if (data?.managers) {
      query.managers = data.managers;
    }
    if (data?.companies) {
      query.companies = data.companies;
    }
    if (data?.country) {
      query.country = data.country;
    }
    if (data?.platform) {
      query.platform = data.platform;
    }
    if (data?.lastComment) {
      query.lastComment = data.lastComment;
    }
    if (data?.createdAt) {
      query.createdAt = data.createdAt;
    }

    if (data?.lastDepositDate) {
      query.lastDepositDate = data.lastDepositDate;
    }

    if (data?.type) {
      query.type = data.type;
    }

    if (data?.wasOnline) {
      query.wasOnline = data.wasOnline;
    }

    if (data?.firstDepositDate) {
      query.firstDepositDate = data.firstDepositDate;
    }

    if (data?.depositsSum) {
      query.depositsSum = data.depositsSum;
    }

    if (data?.potential) {
      query.potential = data.potential;
    }

    if(data?.managers_null) {
      query.managers_null = true;
    }

    if(data?.commentedManagersIds) {
      query.commentedManagersIds = data.commentedManagersIds;

      if(data?.commentedManagers) {
        query.commentedManagers = 'true';
      } else {
        query.commentedManagers = 'false';
      }
    }

    console.log(query.commentedManagers);
    return this.http.get(`/custom-user/users?${qs.stringify(query)}`);
  }

  createManager(manager: CreateManagerDto): Observable<any> {
    return this.http.post('/auth/local/register', manager);
  }

  getJWTtoken(userId: number): Observable<any> {
    return this.http.post(`/custom-user/get-user-token`, {userId: userId})
  }

  getManagers(options?: { pagination?: PaginationDto, }) {
    const query: any = {};

    if (options?.pagination) {
      query.pagination = options.pagination;
    }

    query['populate'] = ['role', 'teamLead', 'superTeamLeads'];

    return this.http.get<{ data: FullManagerDto[] }>(`/custom-user/managers?${qs.stringify(query)}`).pipe(
      map(response => {
        response.data = response.data
          .map(manager => new FullManagerDto(manager))
          .sort((a: FullManagerDto, b: FullManagerDto) => b.firstName.localeCompare(a.firstName));
        return response;
      })
    )
  }

  getPlatform(): Observable<any> {
    const query: any = {};
    query['sort'] = 'id:ASC';

    return this.http.get(`/platforms?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getUserById(id: string | number, fields?: string[]): Observable<FullUserDto> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    query['populate'] = ['fromLead', 'managers', 'company', 'database'];

    return this.http.get<FullUserDto>(`/users/${id}?${qs.stringify(query)}`);
  }

  setManagers(userId: number, managers: number[]) {
    return this.http.post('/custom-user/set-managers', {data: {user: userId, managers}})
  }

  setManagersToMany(
    users: number[],
    managers: number[],
    divideManagers?: boolean
  ) {
    return this.http.post('/custom-user/set-managers-to-many', {
      data: {
        users,
        managers,
        divideManagers
      }
    });
  }

  setStatusAndManagersToMany(
    users: number[],
    status: UserStatusEnum,
    managers: number[],
    divideManagers?: boolean
  ) {
    return this.http.post('/custom-user/set-status-and-managers-to-many', {
      data: {
        users,
        status,
        managers,
        divideManagers
      }
    });
  }

  total(userId: number) {
    return this.http.get(`/custom-user/total/${userId}`);
  }

  changeEmail(data: ChangeEmailDto) {
    return this.http.post(`/custom-user/change-email`, data);
  }

  updatePasswordByAdmin(data: UpdatePasswordByAdminDto): Observable<any> {
    return this.http.post(`/custom-user/change-password`, data);
  }

  getRole(id: number): Observable<any> {
    const query: any = {};

    query['populate'] = ['role'];

    return this.http.get(`/users/${id}?${qs.stringify(query)}`);
  }

  getMe(): Observable<any> {
    return this.http.get(`/users/me`);
  }

  updateUserById(id: number, user: any): Observable<any> {
    return this.http.put(`/users/${id}`, user);
  }

  massiveStatusChange(status: UserStatusEnum, users: number[]) {
    return this.http.post('/custom-user/change-status', {
      status,
      users,
    })
  }

  deleteUser(userId: number) {
    return this.http.delete(`/users/${userId}`);
  }

  tradeBlock(userId: number, reason: string) {
    return this.http.post('/custom-user/block-user', {
      user: userId,
      reason,
    })
  }

  setLastOnline(id: number) {
    return this.http.put('/custom-user/was-online', {id})
  }

  checkInstance(data: any): Observable<any> {
    return this.http.post('/custom-user/check-existence', data)
  }
}
