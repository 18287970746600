import {EventDataEnum, EventTypeEnum} from "../../../common/enums/event.enum";

export class FullEventDto {
  id: number | null;
  date: Date;
  dataType: EventDataEnum;
  type: EventTypeEnum;
  description: string | undefined;
  status: boolean;
  user: any;
  lead: any;
  manager: any;
  constructor(event: FullEventDto) {
    this.id = event.id;
    this.date = event.date;
    this.type = event.type;
    this.dataType = event.dataType;
    this.description = event.description;
    this.status = event.status || false;
    this.user = event.user;
    this.lead = event.lead;
    this.manager = event.manager;
  }
}
