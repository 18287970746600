<div
  class="layout common-layout is-default"
  [ngClass]="{
    'is-folded': isFolded,
    'is-expand': isExpand
  }">

  <div class="header">
    <div class="logo logo-dark">
      <a routerLink="/">
        <img src="assets/img/logo.jpg" alt="">
        <img class="logo-fold" src="assets/img/logo-fold.jpg" alt="Logo">
      </a>
    </div>
    <div class="nav-wrap">

      <ul class="nav-left">
        <li class="desktop-toggle">
          <a (click)="toggleFold()">
            <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
          </a>
        </li>
        <li class="mobile-toggle">
          <a (click)="toggleExpand()">
            <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
          </a>
        </li>
      </ul>

      <ul class="nav-right">

        <li>

          <a
            nz-dropdown
            nzTrigger="click"
            [nzDropdownMenu]="popNotification"
            [nzPlacement]="'bottomRight'">
            <nz-badge nzDot>
              <i
                nz-icon
                nzType="bell"
                theme="outline">
              </i>
            </nz-badge>
          </a>

          <nz-dropdown-menu #popNotification="nzDropdownMenu">
            <div nz-menu class="pop-notification">

              <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">

                <p class="text-dark font-weight-semibold m-b-0">

                  <i
                    nz-icon
                    nzType="bell"
                    theme="outline">
                  </i>

                  <span class="m-l-10">
                    Задачи
                  </span>

                </p>
                <a
                  nz-button
                  routerLink="/events"
                  nzType="primary"
                  nzSize="small"
                  class="p-v-5">

                  Смотреть все

                </a>
              </div>

              <div class="overflow-y-auto" style="max-height: 400px">
                <nz-list
                  class="ant-list-item-links"
                  [nzDataSource]="eventsList"
                  [nzRenderItem]="item"
                  [nzItemLayout]="'horizontal'">

                  <ng-template #item let-item>

                    <nz-list-item>
                      <a>
                        <div class="d-flex">

                          <nz-avatar
                            nz-tooltip
                            [nzTooltipTitle]="
                            item.type === EventTypeEnum.PHONE ? 'Перезвонить' :
                            item.type === EventTypeEnum.MESSAGE ? 'Отправить сообщение' : 'Другое'"
                            [nzIcon]="
                            item.type === EventTypeEnum.PHONE ? 'phone' :
                            item.type === EventTypeEnum.MESSAGE ? 'message' : 'bars'"
                            [ngClass]="
                            item.type === EventTypeEnum.PHONE ? 'ant-avatar-cyan' :
                            item.type === EventTypeEnum.MESSAGE ? 'ant-avatar-green' : 'ant-avatar-geekblue'">
                          </nz-avatar>

                          <div class="m-l-15">

                            <p
                              *ngIf="item.lead && item.lead.data && item.lead.data.attributes"
                              class="font-size-14 m-b-0 text-dark">
                              <span class="font-weight-semibold">Лид:</span>
                              <a
                                [routerLink]="'/leads/' + item.lead.data.id"
                                [queryParams]="{
                                  redirectURL: document.location.href
                                }">
                                {{item.lead.data.attributes.firstName}}
                                {{item.lead.data.attributes.lastName}}
                              </a>
                            </p>

                            <p
                              *ngIf="item.description"
                              class="font-size-14 m-b-0 text-dark">
                              <span class="font-weight-semibold">Описание:</span>
                              {{item.description}}
                            </p>

                            <p class="font-size-14 m-b-0 text-dark">
                              <span class="font-weight-semibold">Дата:</span>
                              {{item.date | date : 'dd/MM/yyyy HH:mm'}}
                            </p>

                          </div>

                        </div>
                      </a>
                    </nz-list-item>

                  </ng-template>

                </nz-list>
              </div>

            </div>
          </nz-dropdown-menu>

        </li>

        <li>
          <span
            *ngIf="user"
            class="p-h-10 pointer"
            nz-dropdown
            [nzDropdownMenu]="profile"
            [nzTrigger]="'click'"
            [nzPlacement]="'bottomRight'">
            <nz-avatar
              *ngIf="user.firstName && user.lastName"
              class="header__avatar"
              style="background-color:#87d068;"
              [nzText]="user.firstName[0] + user.lastName[0]">
            </nz-avatar>
          </span>

          <nz-dropdown-menu #profile="nzDropdownMenu">
            <ul nz-menu class="p-b-15 p-t-20">

              <li *ngIf="user" class="p-h-20 p-b-15 m-b-10 border-bottom">
                <div>

                  <p class="m-b-0 text-dark font-weight-semibold">
                    <span *ngIf="user.firstName">{{user.firstName}} </span>
                    <span *ngIf="user.lastName"> {{user.lastName}}</span>
                  </p>
                  <p *ngIf="user.email" class="m-b-0 opacity-07">
                    {{user.email}}
                  </p>

                </div>
              </li>

              <li nz-menu-item>

                <a
                  (click)="logOut()"
                  class="p-v-5 d-flex align-items-center justify-content-between w-100">

                  <div>
                    <i
                      class="opacity-04 font-size-16"
                      nz-icon
                      nzType="lock"
                      theme="outline"></i>

                    <span class="m-l-10">Выйти</span>
                  </div>

                  <i
                    class="font-size-10"
                    nz-icon
                    nzType="right"
                    theme="outline">
                  </i>

                </a>
              </li>

            </ul>
          </nz-dropdown-menu>

        </li>
      </ul>

    </div>
  </div>

  <div
    class="side-nav"
    [ngClass]="isFolded ? 'is-folded' : ''">

    <ul
      *ngIf="isFolded !== undefined"
      nz-menu
      nzMode="inline"
      class="ant-menu ant-menu-root ant-menu-inline side-nav-menu"
      [ngClass]="isFolded ? 'ant-menu-inline-collapsed' : ''">

      <ng-container *ngIf="role === UserRoleEnum.ADMIN || role === UserRoleEnum.SUPERMANAGER || role === UserRoleEnum.MANAGER || role === UserRoleEnum.TARGETER">
        <li
          nz-menu-item
          routerLink="/users"

          (mousedown)="openLinkFromTab($event, 'users')"

          routerLinkActive="ant-menu-item-selected">
          <span nz-icon nzType="team"></span>
          <span>Пользователи</span>
        </li>

        <li
          nz-menu-item
          routerLink="/users-autologin"

          (mousedown)="openLinkFromTab($event, 'users-autologin')"

          routerLinkActive="ant-menu-item-selected">
          <span nz-icon nzType="user-switch"></span>
          <span>Автологин юзеры</span>
        </li>

        <li
          nz-submenu
          nzTitle="Лиды"

          (mousedown)="openLinkFromTab($event, 'leads')"

          nzIcon="team">

          <ul>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'leads')"

              routerLink="/leads"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="team"></span>
              <span>Лиды</span>
            </li>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'leads-new')"

              routerLink="/leads-new"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="info-circle"></span>
              <span>Новые лиды</span>
            </li>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'cold-bases')"

              routerLink="/cold-bases"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="team"></span>
              <span>Холодные базы</span>
            </li>

            <li
              *ngIf="role && role === UserRoleEnum.ADMIN"
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'leads-draft')"

              routerLink="/leads-draft"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="usergroup-delete"></span>
              <span>Корзина</span>
            </li>

          </ul>
        </li>

        <ng-container *ngIf="role === UserRoleEnum.ADMIN">
          <li nz-menu-item

              (click)="transferComment()"

              routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="wechat" nzTheme="outline"></span>
            <span>Перенос комментариев</span>
          </li>
        </ng-container>

        <ng-container *ngIf="role !== UserRoleEnum.TARGETER">
          <li
            nz-menu-item
            routerLink="/events"

            (mousedown)="openLinkFromTab($event, 'events')"

            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="bell"></span>
            <span>Задачи</span>
          </li>

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'notifications')"

            routerLink="/notifications"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="notification"></span>
            <span>Оповещения</span>
          </li>

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'transactions')"

            routerLink="/transactions"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="transaction"></span>
            <span>Транзакции</span>
          </li>

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'orders')"

            routerLink="/orders"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="area-chart"></span>
            <span>Сделки</span>
          </li>

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'tickets')"

            routerLink="/tickets"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="read"></span>
            <span>Запросы в поддержку</span>
          </li>
        </ng-container>

        <ng-container *ngIf="role === UserRoleEnum.ADMIN">
          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'import-csv')"

            routerLink="/import-csv"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="file-word" nzTheme="outline"></span>
            <span>Загрузка CSV</span>
          </li>

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'history')"

            routerLink="/history"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="history"></span>
            <span>История</span>
          </li>
        </ng-container>

        <ng-container *ngIf="role && (role === UserRoleEnum.ADMIN || role === UserRoleEnum.SUPERMANAGER)">
          <li
            nz-submenu
            nzTitle="Статистика"
            nzIcon="bar-chart">

            <ul>

              <li
                nz-menu-item
                (mousedown)="openLinkFromTab($event, 'statistics')"

                routerLink="/statistics"
                routerLinkActive="ant-menu-item-selected">
                <span nz-icon nzType="coffee"></span>
                <span>Гарячка</span>
              </li>

              <li
                nz-menu-item
                (mousedown)="openLinkFromTab($event, 'statistics-cold')"

                routerLink="/statistics-cold"
                routerLinkActive="ant-menu-item-selected">
                <span nz-icon nzType="hdd"></span>
                <span>Холодные базы</span>
              </li>

              <li
                *ngIf="role && role === UserRoleEnum.ADMIN"
                nz-menu-item
                (mousedown)="openLinkFromTab($event, 'statistics-deposits')"

                routerLink="/statistics-deposits"
                routerLinkActive="ant-menu-item-selected">
                <span nz-icon nzType="money-collect" nzTheme="outline"></span>
                <span>Депозиты</span>
              </li>

              <li
                *ngIf="role && role === UserRoleEnum.ADMIN"
                nz-menu-item
                (mousedown)="openLinkFromTab($event, 'daily-count')"

                routerLink="/daily-count"
                routerLinkActive="ant-menu-item-selected">
                <span nz-icon nzType="percentage" nzTheme="outline"></span>
                <span>Дейли капа</span>
              </li>

              <li
                nz-menu-item
                (mousedown)="openLinkFromTab($event, 'statistics-managers')"

                routerLink="/statistics-managers"
                routerLinkActive="ant-menu-item-selected">
                <span nz-icon nzType="usergroup-add" nzTheme="outline"></span>
                <span>Менеджеры</span>
              </li>


            </ul>
          </li>

<!--          <li-->
<!--            nz-menu-item-->

<!--            nz-tooltip-->
<!--            nzTooltipPlacement="top"-->
<!--            nzTooltipTitle="Потенциал ретеншн"-->

<!--            (mousedown)="openLinkFromTab($event, 'potential')"-->

<!--            routerLink="/potential"-->
<!--            routerLinkActive="ant-menu-item-selected">-->
<!--            <span nz-icon nzType="rise" nzTheme="outline"></span>-->
<!--            <span>Потенциал ретеншн</span>-->
<!--          </li>-->

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'managers')"

            routerLink="/managers"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="user"></span>
            <span>Менеджеры</span>
          </li>
        </ng-container>

        <ng-container *ngIf="role === UserRoleEnum.ADMIN">
          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'companies')"

            routerLink="/companies"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="database"></span>
            <span>Потоки</span>
          </li>
        </ng-container>

        <ng-container *ngIf="false">
          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'black-list')"

            routerLink="/black-list"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="stop"></span>
            <span>Чёрный список</span>
          </li>

          <li
            nz-menu-item

            (mousedown)="openLinkFromTab($event, 'statistics2')"

            routerLink="/statistics2"
            routerLinkActive="ant-menu-item-selected">
            <span nz-icon nzType="radar-chart"></span>
            <span>Статистика | Базы</span>
          </li>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="role === UserRoleEnum.CRM_MANAGER">
        <li
          nz-submenu
          nzTitle="Лиды"

          (mousedown)="openLinkFromTab($event, 'leads')"

          nzIcon="team">

          <ul>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'leads')"

              routerLink="/leads"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="team"></span>
              <span>Лиды</span>
            </li>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'leads-new')"

              routerLink="/leads-new"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="info-circle"></span>
              <span>Новые лиды</span>
            </li>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'cold-bases')"

              routerLink="/cold-bases"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="team"></span>
              <span>Холодные базы</span>
            </li>

          </ul>
        </li>

        <li
          nz-menu-item
          routerLink="/events"

          (mousedown)="openLinkFromTab($event, 'events')"

          routerLinkActive="ant-menu-item-selected">
          <span nz-icon nzType="bell"></span>
          <span>Задачи</span>
        </li>

        <li
          nz-submenu
          nzTitle="Статистика"
          nzIcon="bar-chart">

          <ul>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'statistics')"

              routerLink="/statistics"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="coffee"></span>
              <span>Гарячка</span>
            </li>

            <li
              nz-menu-item
              (mousedown)="openLinkFromTab($event, 'statistics-cold')"

              routerLink="/statistics-cold"
              routerLinkActive="ant-menu-item-selected">
              <span nz-icon nzType="hdd"></span>
              <span>Холодные базы</span>
            </li>

          </ul>
        </li>

        <li
          nz-menu-item

          (mousedown)="openLinkFromTab($event, 'managers')"

          routerLink="/managers"
          routerLinkActive="ant-menu-item-selected">
          <span nz-icon nzType="user"></span>
          <span>Менеджеры</span>
        </li>

      </ng-container>

    </ul>

  </div>

  <div class="page-container">
    <div class="main-content">
      <main class="content">
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>

</div>

<ng-template #template let-notification="data">
  <div class="ant-notification-notice-content">
    <div>
      <div class="ant-notification-notice-message">

        <div class="d-flex align-items-center justify-content-between">
          <p class="font-size-14 m-b-0 text-dark m-b-0">
            <span class="font-weight-semibold">Дата:</span>
            {{notification.date | date : 'dd/MM/yyyy HH:mm'}}
          </p>

          <nz-tag
            nzColor="green"
            class="m-b-0">
            {{notification.type === EventTypeEnum.PHONE ? 'Звонок' : notification.type === EventTypeEnum.MESSAGE ? 'Сообщение' : 'Другое'}}
          </nz-tag>
        </div>
      </div>
      <div class="ant-notification-notice-description">

        <p
          *ngIf="notification.dataType === EventDataEnum.LEAD && notification.lead && notification.lead.data && notification.lead.data.attributes"
          class="m-b-0 text-dark">
          <span class="font-weight-semibold">Лид:</span>
          <a
            [routerLink]="'/leads/' + notification.lead.data.id"
            target="_blank"
            [queryParams]="{
               redirectURL: document.location.href
            }">
            {{notification.lead.data.attributes.firstName}}
            {{notification.lead.data.attributes.lastName}}
          </a>
        </p>

        <p
          *ngIf="notification.dataType === EventDataEnum.USER && notification.user && notification.user.data && notification.user.data.attributes"
          class="m-b-0 text-dark">
          <span class="font-weight-semibold">Пользователь:</span>
          <a
            [routerLink]="'/users/' + notification.user.data.id + '/orders'"
            target="_blank"
            [queryParams]="{
               redirectURL: document.location.href
            }">
            {{notification.user.data.attributes.firstName}}
            {{notification.user.data.attributes.lastName}}
          </a>
        </p>

        <p
          *ngIf="notification.description"
          class="m-b-0 text-dark">
          <span class="font-weight-semibold">Описание:</span>
          {{notification.description}}
        </p>

      </div>

      <div
        *ngIf="notification.id"
        class="ant-notification-notice-btn">

        <a
          *ngIf="notification.dataType === EventDataEnum.LEAD && notification.lead && notification.lead.data && notification.lead.data.id"
          nz-button
          nzType="default"
          nzSize="small"
          [routerLink]="'/leads/' + notification.lead.data.id"
          [queryParams]="{
               redirectURL: document.location.href
          }"
          target="_blank"
          class="m-r-15">
          <span>Перейти</span>
        </a>

        <a
          *ngIf="notification.dataType === EventDataEnum.USER && notification.user && notification.user.data && notification.user.data.id"
          nz-button
          nzType="default"
          nzSize="small"
          [routerLink]="'/users/' + notification.user.data.id + '/orders'"
          [queryParams]="{
               redirectURL: document.location.href
          }"
          target="_blank"
          class="m-r-15">
          <span>Перейти</span>
        </a>

        <button
          nz-button
          nzType="primary"
          nzSize="small"
          (click)="showConfirmChangeEventStatus(notification.id)">
          <span>Завершить</span>
        </button>

      </div>

    </div>
  </div>
</ng-template>
