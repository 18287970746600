import {CountryISO} from "ngx-intl-tel-input";

export const countries = [
  {"text": "Молдова", "value": "MD"},
  {"text": "Аландские острова", "value": "AX"},
  {"text": "Афганистан", "value": "AF"},
  {"text": "Албания", "value": "AL"},
  {"text": "Алжир", "value": "DZ"},
  {"text": "Американское Самоа", "value": "AS"},
  {"text": "Андорра", "value": "AD"},
  {"text": "Ангола", "value": "AO"},
  {"text": "Ангилья", "value": "AI"},
  {"text": "Антарктида", "value": "AQ"},
  {"text": "Антигуа и Барбуда", "value": "AG"},
  {"text": "Аргентина", "value": "AR"},
  {"text": "Армения", "value": "AM"},
  {"text": "Аруба", "value": "AW"},
  {"text": "Австралия", "value": "AU"},
  {"text": "Австрия", "value": "AT"},
  {"text": "Азербайджан", "value": "AZ"},
  {"text": "Багамы", "value": "BS"},
  {"text": "Бахрейн", "value": "BH"},
  {"text": "Бангладеш", "value": "BD"},
  {"text": "Барбадос", "value": "BB"},
  {"text": "Беларусь", "value": "BY"},
  {"text": "Бельгия", "value": "BE"},
  {"text": "Белиз", "value": "BZ"},
  {"text": "Бенин", "value": "BJ"},
  {"text": "Бермуды", "value": "BM"},
  {"text": "Бутан", "value": "BT"},
  {"text": "Боливия", "value": "BO"},
  {"text": "Босния и Герцеговина", "value": "BA"},
  {"text": "Ботсвана", "value": "BW"},
  {"text": "Остров Буве", "value": "BV"},
  {"text": "Бразилия", "value": "BR"},
  {"text": "Британская территория в Индийском океане", "value": "IO"},
  {"text": "Бруней", "value": "BN"},
  {"text": "Болгария", "value": "BG"},
  {"text": "Буркина-Фасо", "value": "BF"},
  {"text": "Бурунди", "value": "BI"},
  {"text": "Камбоджа", "value": "KH"},
  {"text": "Камерун", "value": "CM"},
  {"text": "Канада", "value": "CA"},
  {"text": "Кабо-Верде", "value": "CV"},
  {"text": "Каймановы острова", "value": "KY"},
  {"text": "Центрально-Африканская Республика", "value": "CF"},
  {"text": "Чад", "value": "TD"},
  {"text": "Чили", "value": "CL"},
  {"text": "Китай", "value": "CN"},
  {"text": "Остров Рождества", "value": "CX"},
  {"text": "Колумбия", "value": "CO"},
  {"text": "Коморы", "value": "KM"},
  {"text": "Острова Кука", "value": "CK"},
  {"text": "Коста-Рика", "value": "CR"},
  {"text": "Кот-д'Ивуар", "value": "CI"},
  {"text": "Куба", "value": "CU"},
  {"text": "Кипр", "value": "CY"},
  {"text": "Чешская Республика", "value": "CZ"},
  {"text": "Дания", "value": "DK"},
  {"text": "Джибути", "value": "DJ"},
  {"text": "Доминика", "value": "DM"},
  {"text": "Доминиканская Республика", "value": "DO"},
  {"text": "Эквадор", "value": "EC"},
  {"text": "Египет", "value": "EG"},
  {"text": "Сальвадор", "value": "SV"},
  {"text": "Экваториальная Гвинея", "value": "GQ"},
  {"text": "Эритрея", "value": "ER"},
  {"text": "Эстония", "value": "EE"},
  {"text": "Эфиопия", "value": "ET"},
  {"text": "Фарерские острова", "value": "FO"},
  {"text": "Фиджи", "value": "FJ"},
  {"text": "Финляндия", "value": "FI"},
  {"text": "Франция", "value": "FR"},
  {"text": "Гайана", "value": "GF"},
  {"text": "Французская Полинезия", "value": "PF"},
  {"text": "Французские Южные Территории", "value": "TF"},
  {"text": "Габон", "value": "GA"},
  {"text": "Гамбия", "value": "GM"},
  {"text": "Грузия", "value": "GE"},
  {"text": "Германия", "value": "DE"},
  {"text": "Гана", "value": "GH"},
  {"text": "Гибралтар", "value": "GI"},
  {"text": "Греция", "value": "GR"},
  {"text": "Гренландия", "value": "GL"},
  {"text": "Гренада", "value": "GD"},
  {"text": "Гваделупа", "value": "GP"},
  {"text": "Гуам", "value": "GU"},
  {"text": "Гватемала", "value": "GT"},
  {"text": "Гвинея", "value": "GN"},
  {"text": "Гвинея-Бисау", "value": "GW"},
  {"text": "Гайана", "value": "GY"},
  {"text": "Гаити", "value": "HT"},
  {"text": "Остров Херд и острова Макдональд", "value": "HM"},
  {"text": "Гондурас", "value": "HN"},
  {"text": "Гонконг", "value": "HK"},
  {"text": "Венгрия", "value": "HU"},
  {"text": "Исландия", "value": "IS"},
  {"text": "Индия", "value": "IN"},
  {"text": "Индонезия", "value": "ID"},
  {"text": "Ирак", "value": "IQ"},
  {"text": "Ирландия", "value": "IE"},
  {"text": "Израиль", "value": "IL"},
  {"text": "Италия", "value": "IT"},
  {"text": "Ямайка", "value": "JM"},
  {"text": "Япония", "value": "JP"},
  {"text": "Иордания", "value": "JO"},
  {"text": "Казахстан", "value": "KZ"},
  {"text": "Кения", "value": "KE"},
  {"text": "Кирибати", "value": "KI"},
  {"text": "Кувейт", "value": "KW"},
  {"text": "Кыргызстан", "value": "KG"},
  {"text": "Лаос", "value": "LA"},
  {"text": "Латвия", "value": "LV"},
  {"text": "Ливан", "value": "LB"},
  {"text": "Лесото", "value": "LS"},
  {"text": "Либерия", "value": "LR"},
  {"text": "Ливия", "value": "LY"},
  {"text": "Лихтенштейн", "value": "LI"},
  {"text": "Литва", "value": "LT"},
  {"text": "Люксембург", "value": "LU"},
  {"text": "Макао", "value": "MO"},
  {"text": "Мадагаскар", "value": "MG"},
  {"text": "Малави", "value": "MW"},
  {"text": "Малайзия", "value": "MY"},
  {"text": "Мальдивы", "value": "MV"},
  {"text": "Мали", "value": "ML"},
  {"text": "Мальта", "value": "MT"},
  {"text": "Маршалловы острова", "value": "MH"},
  {"text": "Мартиника", "value": "MQ"},
  {"text": "Мавритания", "value": "MR"},
  {"text": "Маврикий", "value": "MU"},
  {"text": "Майотта", "value": "YT"},
  {"text": "Мексика", "value": "MX"},
  {"text": "Монако", "value": "MC"},
  {"text": "Монголия", "value": "MN"},
  {"text": "Монтсеррат", "value": "MS"},
  {"text": "Марокко", "value": "MA"},
  {"text": "Мозамбик", "value": "MZ"},
  {"text": "Мьянма", "value": "MM"},
  {"text": "Намибия", "value": "NA"},
  {"text": "Науру", "value": "NR"},
  {"text": "Непал", "value": "NP"},
  {"text": "Нидерланды", "value": "NL"},
  {"text": "Нидерландские Антильские острова", "value": "AN"},
  {"text": "Новая Каледония", "value": "NC"},
  {"text": "Новая Зеландия", "value": "NZ"},
  {"text": "Никарагуа", "value": "NI"},
  {"text": "Нигер", "value": "NE"},
  {"text": "Нигерия", "value": "NG"},
  {"text": "Ниуэ", "value": "NU"},
  {"text": "Остров Норфолк", "value": "NF"},
  {"text": "Северные Марианские острова", "value": "MP"},
  {"text": "Норвегия", "value": "NO"},
  {"text": "Оман", "value": "OM"},
  {"text": "Пакистан", "value": "PK"},
  {"text": "Палау", "value": "PW"},
  {"text": "Панама", "value": "PA"},
  {"text": "Папуа-Новая Гвинея", "value": "PG"},
  {"text": "Парагвай", "value": "PY"},
  {"text": "Перу", "value": "PE"},
  {"text": "Филиппины", "value": "PH"},
  {"text": "Питкэрн", "value": "PN"},
  {"text": "Польша", "value": "PL"},
  {"text": "Португалия", "value": "PT"},
  {"text": "Пуэрто-Рико", "value": "PR"},
  {"text": "Катар", "value": "QA"},
  {"text": "Реюньон", "value": "RE"},
  {"text": "Румыния", "value": "RO"},
  {"text": "Российская Федерация", "value": "RU"},
  {"text": "Руанда", "value": "RW"},
  {"text": "Святая Елена", "value": "SH"},
  {"text": "Сент-Китс и Невис", "value": "KN"},
  {"text": "Сент-Люсия", "value": "LC"},
  {"text": "Сен-Пьер и Микелон", "value": "PM"},
  {"text": "Сент-Винсент и Гренадины", "value": "VC"},
  {"text": "Самоа", "value": "WS"},
  {"text": "Сан-Марино", "value": "SM"},
  {"text": "Сан-Томе и Принсипи", "value": "ST"},
  {"text": "Саудовская Аравия", "value": "SA"},
  {"text": "Сенегал", "value": "SN"},
  {"text": "Сербия и Черногория", "value": "CS"},
  {"text": "Сейшелы", "value": "SC"},
  {"text": "Сьерра-Леоне", "value": "SL"},
  {"text": "Сингапур", "value": "SG"},
  {"text": "Словакия", "value": "SK"},
  {"text": "Словения", "value": "SI"},
  {"text": "Соломоновы острова", "value": "SB"},
  {"text": "Сомали", "value": "SO"},
  {"text": "Южная Африка", "value": "ZA"},
  {"text": "Южная Георгия и Южные Сандвичевы острова", "value": "GS"},
  {"text": "Испания", "value": "ES"},
  {"text": "Шри-Ланка", "value": "LK"},
  {"text": "Судан", "value": "SD"},
  {"text": "Суринам", "value": "SR"},
  {"text": "Шпицберген и Ян-Майен", "value": "SJ"},
  {"text": "Свазиленд", "value": "SZ"},
  {"text": "Швеция", "value": "SE"},
  {"text": "Швейцария", "value": "CH"},
  {"text": "Сирийская Арабская Республика", "value": "SY"},
  {"text": "Тайвань", "value": "TW"},
  {"text": "Таджикистан", "value": "TJ"},
  {"text": "Таиланд", "value": "TH"},
  {"text": "Тимор-Лешти", "value": "TL"},
  {"text": "Того", "value": "TG"},
  {"text": "Токелау", "value": "TK"},
  {"text": "Тонга", "value": "TO"},
  {"text": "Тринидад и Тобаго", "value": "TT"},
  {"text": "Тунис", "value": "TN"},
  {"text": "Турция", "value": "TR"},
  {"text": "Туркменистан", "value": "TM"},
  {"text": "Теркс и Кайкос", "value": "TC"},
  {"text": "Тувалу", "value": "TV"},
  {"text": "Уганда", "value": "UG"},
  {"text": "Украина", "value": "UA"},
  {"text": "Объединенные Арабские Эмираты", "value": "AE"},
  {"text": "Соединенное Королевство", "value": "GB"},
  {"text": "Соединенные Штаты", "value": "US"},
  {"text": "Внешние малые острова США", "value": "UM"},
  {"text": "Уругвай", "value": "UY"},
  {"text": "Узбекистан", "value": "UZ"},
  {"text": "Вануату", "value": "VU"},
  {"text": "Венесуэла", "value": "VE"},
  {"text": "Вьетнам", "value": "VN"},
  {"text": "Уоллис и Футуна", "value": "WF"},
  {"text": "Западная Сахара", "value": "EH"},
  {"text": "Йемен", "value": "YE"},
  {"text": "Замбия", "value": "ZM"},
  {"text": "Зимбабве", "value": "ZW"}
];

export const preferredCountries: CountryISO[] = [
  CountryISO.Russia,
  CountryISO.Lithuania,
  CountryISO.Latvia,
  CountryISO.Estonia,
  CountryISO.Germany,
  CountryISO.Romania,
  CountryISO.Moldova,
  CountryISO.UnitedKingdom,
  CountryISO.Belarus,
  CountryISO.Spain,
  CountryISO.Italy,
  CountryISO.Portugal,
  CountryISO.Poland,
];

