import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {DocumentsTypeEnum} from "../../common/enums/documentsType.enum";
import {DocumentCreateDto} from "./dto/documentCreate.dto";
import {WithdrawalMethodFullDto} from "../withdrawal/dto/withdrawalMethodFull.dto";
import {DocumentsDto} from "./dto/documents.dto";
import {DocumentUpdateDto} from "./dto/documentUpdate.dto";

@Injectable({
  providedIn: 'root'
})
export class DocumentsRestService {
  constructor(private http: HttpClient) {
  }

  getDocuments(id?: number, pagination?: PaginationDto, type?: DocumentsTypeEnum): Observable<any> {
    const query: any = {};

    query['sort'] = 'id:DESC';
    query['populate'] = ['files']

    if (pagination) {
      query['pagination'] = pagination;
    }

    if (type) {
      query['filters'] = {
        type: {
          $eq: type,
        },
      };
    }

    if(id) {
      query['filters'] = {
        user: {
          id: {
            $eq: id,
          },
        },
      };
    }

    return this.http.get(`/documents?${qs.stringify(query)}`).pipe(
      map(response => getAllMapAttributesToObj(response))
    );
  }

  getDocumentById(id: number, fields ?: string[]): Observable<any> {
    const query: any = {};

    if (fields) {
      query['fields'] = fields;
    }

    query['populate'] = ['files']

    return this.http.get(`/documents/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  createDocument(document: DocumentCreateDto): Observable<any> {
    return this.http.post('/documents', {data: document});
  }

  updateDocumentById(id: number, document: DocumentUpdateDto): Observable<any> {
    return this.http.put(`/documents/${id}`, {data: document});
  }

  deleteDocumentById(id: number): Observable<any> {
    return this.http.delete(`/documents/${id}`);
  }

  public uploadFile<T>(files: File[]): Observable<any> {
    let formData = new FormData();

    for (let file of files) {
      formData.append('files', file, file.name);
    }

    return this.http
      .post<T>(
        "/upload",
        formData
      );
  }
}
