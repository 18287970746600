import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MessagesService} from '../common/services/message-service/messages.service';
import {LanguageService} from "../common/services/language/language.service";
import {FullUserDto} from "../rest/user/dto/fullUser.dto";
import {UserRestService} from "../rest/user/user-rest.service";
import {AuthService} from "../common/auth/auth.service";
import {UserRoleEnum} from "../common/enums/userRole.enum";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {PaginationDto} from "../common/pagination/pagination.dto";
import {EventDataEnum, EventTypeEnum} from "../common/enums/event.enum";
import {FullEventDto} from "../rest/events/dto/fullEvent.dto";
import {EventsService} from "../rest/events/events.service";
import {NzModalService} from "ng-zorro-antd/modal";
import {TransferCommentComponent} from "../component/transfer-comment/transfer-comment.component";
import {LeadStatusEnum} from "../pages/leads/lead-status.enum";
import {ProjectTypeEnum} from "../common/enums/projectType.enum";
import {ProjectType} from "../common/services/settings/project";
import {SoundService} from "../common/services/sound/sound.service";

@Component({
  selector: 'app-layouts',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit, OnDestroy {
  @ViewChild('template', {static: false}) template?: TemplateRef<{}>;

  user: FullUserDto | undefined;
  role: UserRoleEnum = UserRoleEnum.MANAGER;

  isFolded: boolean | undefined;
  isExpand: boolean | undefined;

  currentDate: Date | undefined;
  date = new Date();

  UserRoleEnum = UserRoleEnum;
  LeadStatusEnum = LeadStatusEnum;

  paginationOptions: PaginationDto = new PaginationDto({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    total: 0,
  });

  EventTypeEnum = EventTypeEnum;
  EventDataEnum = EventDataEnum;

  eventsList: FullEventDto[] = [];
  outStandingEvents: FullEventDto[] = [];

  trigger: any;

  document = document;

  initEvent = false;

  // project
  ProjectTypeEnum = ProjectTypeEnum;
  ProjectType = ProjectType;

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private userRestService: UserRestService,
    private messageServices: MessagesService,
    private eventsService: EventsService,
    private modal: NzModalService,
    private authService: AuthService,
    private soundService: SoundService,
    private notificationService: NzNotificationService,
  ) {
  }

  ngOnInit(): void {
    document.body.click();

    this.getUser();
    this.getSideMenuParams();
    this.addEventTrigger();

    this.setCurrentDate();

    this.trigger = setInterval(() => {
      this.getEvents();
    }, 90 * 1000);
  }

  ngOnDestroy(): void {
    if (this.trigger) {
      clearInterval(this.trigger);
    }
  }

  toggleFold() {
    this.isFolded = !this.isFolded;

    this.setSideMenuParams();
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;

    this.setSideMenuParams();
  }

  setSideMenuParams(): void {
    // @ts-ignore
    localStorage.setItem('isExpand', this.isExpand ? this.isExpand : JSON.stringify(false));
    // @ts-ignore
    localStorage.setItem('isFolded', this.isFolded ? this.isFolded : JSON.stringify(false));
  }

  getSideMenuParams(): void {
    // @ts-ignore
    this.isExpand = localStorage.getItem('isExpand') ? JSON.parse(localStorage.getItem('isExpand')) : false;
    // @ts-ignore
    this.isFolded = localStorage.getItem('isFolded') ? JSON.parse(localStorage.getItem('isFolded')) : false;
  }

  setCurrentDate(): void {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 5);

    this.currentDate = currentDate;
  }

  getUser(): void {
    this.userRestService.getMe().subscribe(
      user => {
        this.user = new FullUserDto(user);

        this.authService.setId(this.user.id);
        this.authService.setManager(this.user);

        this.getRole();
        this.setCurrentDate();
        this.getEvents();

        if (this.user.id) {
          this.getLastOnlineTime();
        }
      }
    )
  }

  getRole(): void {
    if (this.user && this.user.id) {
      this.userRestService.getRole(this.user.id).subscribe(
        response => {
          if (response.role.name) {
            this.role = response.role.name;
          }
          this.authService.setRole(this.role);

          if (this.role === 'Customer') {
            this.authService.logout();
            this.messageServices.createMessage(
              'error',
              'Произошла ошибка при попытке входа.'
            );
          }
        }
      )
    }
  }

  logOut(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
    this.messageServices.createMessage(
      'success',
      'Вы успешно вышли из системы.',
    );
  }

  getEvents(): void {
    if (this.user && this.user.id) {
      this.eventsService.getRecentTasks({
        pagination: this.paginationOptions,
        manager: this.user.id,
      }).subscribe(
        response => {
          this.eventsList = response.data;
        }
      );

      this.eventsService.getRecentTasks({
        pagination: this.paginationOptions,
        manager: this.user.id,
        date: this.currentDate,
      }).subscribe(
        response => {
          this.notificationService.remove();

          // if(this.initEvent) {
          //   if (this.outStandingEvents.length !== response.data.length) {
          //     this.soundService.playNotificationSound();
          //   } else {
          //
          //     for (let i = 0; i < this.outStandingEvents.length; i++) {
          //       let found = false;
          //       for (let j = 0; j < response.data.length; j++) {
          //         if (this.outStandingEvents[i].id === response.data[j].id) {
          //           found = true;
          //           break;
          //         }
          //       }
          //       if (!found) {
          //         this.soundService.playNotificationSound();
          //         break;
          //       }
          //     }
          //   }
          // }

          if(!this.initEvent) {
            this.initEvent = true;
          }

          this.outStandingEvents = response.data;

          if (this.outStandingEvents) {
            this.outStandingEvents.forEach(event => {
              this.notificationService.template(this.template!, {
                nzData: event,
                nzPlacement: 'bottomLeft',
                nzDuration: 6 * 60 * 1000,
              });
            });
          }
        }
      )
    }
  }

  addEventTrigger(): void {
    this.authService.addEventTrigger.subscribe(
      status => {
        if (status) {
          this.getEvents();
        }
      }
    )
  }

  showConfirmChangeEventStatus(id: number): void {
    this.modal.confirm({
      nzTitle: 'Вы хотите завершить даную задачу?',
      nzOnOk: () => this.updateEventStatus(id),
      nzOkText: 'Завершить'
    });
  }

  updateEventStatus(id: number): void {
    if (id) {
      this.eventsService.updateEvent(id, {status: true}).subscribe(
        _ => {
          this.notificationService.remove();
          this.authService.eventTrigger(true);
          this.getEvents();

          this.messageServices.createMessage(
            'success',
            'Статус задачи успешно изменен.'
          );
        }
      )
    }
  }

  // set and get last online time
  setLastOnlineTime() {
    if (this.user && this.user.id) {
      this.userRestService.setLastOnline(this.user.id)
        .subscribe();
    }

    localStorage.setItem('lastOnlineTime', String(this.date));
  }

  getLastOnlineTime(): void {
    const localStorageTime = localStorage.getItem('lastOnlineTime');

    if (!localStorageTime || !this.isSameDay(new Date(localStorageTime), this.date)) {
      this.setLastOnlineTime();
    }
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  // open new tab
  openLinkFromTab(event: any, url: string) {
    if (event.button === 1) {
      window.open(`/${url}`, '_blank');
    }
  }

  // transfer comment from lead to user
  transferComment(): void {
    const modal = this.modal.create({
      nzTitle: 'Перенос комментария',
      nzContent: TransferCommentComponent,
      nzFooter: null,
    })
  }
}
