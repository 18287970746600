import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CreateEventDto} from "./dto/createEvent.dto";
import {Observable} from "rxjs";
import {PaginationDto} from "../../common/pagination/pagination.dto";
import * as qs from "qs";
import {map} from "rxjs/operators";
import {getAllMapAttributesToObj, getOneMapAttributesToObj} from "../../common/services/mapAttributes/mapAttributes";
import {FullEventDto} from "./dto/fullEvent.dto";

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(
    private http: HttpClient
  ) {
  }

  getTasksByManager(options: {
    pagination: PaginationDto,
    manager: number[],
    date?: Date[],
  }) {
    const query: any = {};
    query['filters'] = {};

    query['populate'] = ['user', 'lead', 'manager'];

    if (options.pagination) {
      query.pagination = options.pagination;
    }

    if (options.manager) {
      query['filters'].manager = {
        id: {
          $eq: options.manager
        }
      }
    }

    if (options.date && options.date.length >= 2) {
      query['filters'].date = {
        $gte: options.date[0],
        $lte: options.date[1],
      }
    }

    return this.http.get(`/events?${qs.stringify(query)}`).pipe(
      map((response: any) => {
        response.data = getAllMapAttributesToObj(response).data.map((event: any) => new FullEventDto(event));
        return response;
      })
    );
  }

  getRecentTasks(options: {
    pagination: PaginationDto,
    manager: number,
    date?: Date,
  }) {
    const query: any = {};
    query['filters'] = {};

    query['populate'] = ['user', 'lead'];
    query['sort'] = 'date:ASC';

    if (options.pagination) {
      query.pagination = options.pagination;
    }

    if (options.manager) {
      query['filters'].manager = {
        id: {
          $eq: options.manager
        }
      }
    }

    if (options.date) {
      query['filters'].date = {
        $lte: options.date,
      }
    }

    query['filters'].status = {
      $eq: 'false',
    };

    return this.http.get(`/events?${qs.stringify(query)}`).pipe(
      map((response: any) => {
        response.data = getAllMapAttributesToObj(response).data.map((event: any) => new FullEventDto(event));
        return response;
      })
    );
  }

  getTasksLeadAndUser(options: {
    pagination: PaginationDto,
    lead?: number,
    user?: number,
    manager: number,
  }) {
    const query: any = {};
    query['filters'] = {};

    query['populate'] = ['user', 'lead', 'manager'];
    query['sort'] = 'date:DESC';

    if (options.pagination) {
      query.pagination = options.pagination;
    }

    if (options.manager) {
      query['filters'].manager = {
        id: {
          $eq: options.manager
        }
      }
    }

    if (options.lead) {
      query['filters'].lead = {
        id: {
          $eq: options.lead
        }
      }
    }

    if (options.user) {
      query['filters'].user = {
        id: {
          $eq: options.user
        }
      }
    }

    return this.http.get(`/events?${qs.stringify(query)}`).pipe(
      map((response: any) => {
        response.data = getAllMapAttributesToObj(response).data.map((event: any) => new FullEventDto(event));
        return response;
      })
    );
  }

  getEvent(id: number): Observable<any> {
    const query: any = {};
    query['populate'] = ['user', 'lead'];

    return this.http.get(`/events/${id}?${qs.stringify(query)}`).pipe(
      map(response => getOneMapAttributesToObj(response))
    );
  }

  createEvent(event: CreateEventDto): Observable<any> {
    return this.http.post('/events', {data: event});
  }

  updateEvent(id: number, event: any): Observable<any> {
    return this.http.put(`/events/${id}`, {data: event});
  }

  deleteEvent(id: number): Observable<any> {
    return this.http.delete(`/events/${id}`);
  }
}
