<form
  *ngIf="filter"
  nz-form
  #updateFilterForm="ngForm"
  autocomplete="off"
  nzLayout="vertical"
  (ngSubmit)="updateFilterForm.valid && updateFilter()">

  <div class="row">
    <div class="col-md-12">
      <nz-form-item>

        <nz-form-control>

          <nz-form-label
            nzFor="name"
            class="p-b-0 m-b-0">
            Название фильтра
          </nz-form-label>

          <nz-form-control>
            <nz-input-group>
              <input
                #name="ngModel"
                type="text"

                name="name"
                nz-input
                required

                [(ngModel)]="filter.data.name"
                placeholder="Название фильтра">
            </nz-input-group>
          </nz-form-control>

          <div
            *ngIf="name.invalid && (name.dirty || name.touched)"
            class="form_error">
            <span *ngIf="name.errors?.['required']">Обязательное поле.</span>
          </div>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item>

        <nz-form-control>

          <nz-form-label
            nzFor="createdAtDate"
            class="p-b-0 m-b-0">
            Дата добавления
          </nz-form-label>

          <nz-select
            id="createdAtDate"
            name="createdAtDate"

            [nzMaxTagCount]="1"
            [nzMaxMultipleCount]="5"

            nzAllowClear
            nzShowSearch

            nzPlaceHolder="Дата добавления"

            [(ngModel)]="filter.data.filters.createdAtDate"
            (ngModelChange)="changeCreatedAtDate($event)">

            <nz-option
              *ngFor="let date of ranges"
              [nzValue]="date.enum"
              [nzLabel]="date.text">
            </nz-option>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item>

        <nz-form-control>

          <nz-form-label
            nzFor="lastCommentDate"
            class="p-b-0 m-b-0">
            Дата комментария
          </nz-form-label>

          <nz-select
            id="lastCommentDate"
            name="lastCommentDate"

            [nzMaxTagCount]="1"
            [nzMaxMultipleCount]="5"

            nzAllowClear
            nzShowSearch

            nzPlaceHolder="Дата добавления"

            [(ngModel)]="filter.data.filters.lastCommentDate"
            (ngModelChange)="changeCommentDate($event)">

            <nz-option
              *ngFor="let date of ranges"
              [nzValue]="date.enum"
              [nzLabel]="date.text">
            </nz-option>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item *ngIf="role && (role === UserRoleEnum.ADMIN || role === UserRoleEnum.SUPERMANAGER)">

        <nz-form-control>

          <nz-form-label
            nzFor="managers"
            class="p-b-0 m-b-0">
            Менеджеры
          </nz-form-label>

          <nz-select
            id="manager"
            name="manager"

            [nzMaxTagCount]="1"
            [nzMaxMultipleCount]="15"

            nzAllowClear
            nzShowSearch
            nzMode="multiple"
            nzPlaceHolder="Менеджеры"

            [disabled]="!helpers.loadManagers"

            [(ngModel)]="filter.data.filters.managers"
            (ngModelChange)="changeSelectEvent($event, 'managers')">

            <ng-container *ngFor="let manager of editedManagerList">

              <ng-container *ngIf="manager.managers.length">
                <nz-option-group [nzLabel]="'Team lead: ' + manager.fullName">

                  <nz-option
                    [nzValue]="manager.id"
                    [nzLabel]="manager.fullName">
                  </nz-option>

                  <nz-option
                    *ngFor="let internalManager of manager.managers"
                    [nzValue]="internalManager.id"
                    [nzLabel]="internalManager.fullName">
                  </nz-option>

                </nz-option-group>
              </ng-container>

              <ng-container *ngIf="!manager.managers.length">
                <nz-option
                  [nzValue]="manager.id"
                  [nzLabel]="manager.fullName">
                </nz-option>
              </ng-container>

            </ng-container>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item>

        <nz-form-control>

          <nz-form-label
            nzFor="status"
            class="p-b-0 m-b-0">
            Статусы
          </nz-form-label>

          <nz-select
            name="status"

            [nzMaxTagCount]="1"
            [nzMaxMultipleCount]="20"

            nzAllowClear
            nzShowSearch
            nzMode="multiple"
            nzPlaceHolder="Статусы"

            [(ngModel)]="filter.data.filters.status"
            (ngModelChange)="changeSelectEvent($event, 'status')">

            <ng-container
              *ngFor="let status of statusForFilter">
              <nz-option
                [nzValue]="status.value"
                [nzLabel]="status.text">
              </nz-option>
            </ng-container>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-12">
      <nz-form-item>

        <nz-form-control>

          <nz-form-label
            nzFor="company"
            class="p-b-0 m-b-0">
            Компании
          </nz-form-label>

          <nz-select
            id="company"
            name="company"

            [nzMaxTagCount]="1"
            [nzMaxMultipleCount]="5"

            nzAllowClear
            nzShowSearch
            nzMode="multiple"
            nzPlaceHolder="Компании"

            [(ngModel)]="filter.data.filters.companies"
            (ngModelChange)="changeSelectEvent($event, 'companies')">

            <nz-option
              *ngFor="let company of companiesForFilter"
              [nzValue]="company.value"
              [nzLabel]="company.text">
            </nz-option>

          </nz-select>

        </nz-form-control>

        <label
          nz-checkbox
          name="hideNotActiveCompanies"
          (ngModelChange)="changeActiveCompaniesList($event)"
          [(ngModel)]="data.hideNotActiveCompanies"
          class="m-t-5">
          Скрыть неактивные компании
        </label>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item>

        <nz-form-control>

          <nz-form-label
            nzFor="country"
            class="p-b-0 m-b-0">
            Страна
          </nz-form-label>

          <nz-select
            id="country"
            name="country"

            [nzMaxTagCount]="1"
            [nzMaxMultipleCount]="5"

            nzAllowClear
            nzShowSearch
            nzPlaceHolder="Страна"

            [(ngModel)]="filter.data.filters.country"
            (ngModelChange)="changeSelectEvent($event, 'country')">

            <nz-option
              *ngFor="let country of countryList"
              [nzValue]="country.value"
              [nzLabel]="country.text">
            </nz-option>

          </nz-select>

        </nz-form-control>

      </nz-form-item>
    </div>

    <div class="col-md-6">
      <nz-form-item>
        <nz-form-control>

          <nz-form-label
            nzFor="source"
            class="p-b-0 m-b-0">
            Источник
          </nz-form-label>

          <nz-form-control>
            <nz-input-group>
              <input
                #source="ngModel"
                type="text"

                name="source"
                nz-input

                [(ngModel)]="filter.data.filters.source"
                placeholder="Источник">
            </nz-input-group>
          </nz-form-control>

        </nz-form-control>
      </nz-form-item>
    </div>

    <div class="col-md-12">
      <nz-form-item class="m-b-0">

        <nz-form-control>
          <button
            nz-button
            nzBlock
            nzType="primary"
            nzSize="large"
            (click)="updateFilterForm.form.markAllAsTouched()">
            Сохранить фильтр
          </button>
        </nz-form-control>

      </nz-form-item>
    </div>
  </div>

</form>
